<template>
  <div>
    <div class="RapportDeVente">
            <h2>Rapport</h2>
            <h1>{{ModeOperation == "Vente" ? "Vente (s)" : ModeOperation == "Recouvrement" ? "Recouvrement" : ModeOperation == "Avoir" ? "Avoir" : ""}}</h1>
            <div v-if="ModeOperation === 'Vente' || ModeOperation === 'Avoir'">
                <div class="ZoneTable" v-for="Catégory,n in CatégoryHave" :key="n">
                    <h2 style="margin:30px 0px;">{{Catégory.produits[0].categorie}}</h2>
                    <p>Les produits :</p>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Quantité</th>
                            <th>Réduction Commerciale</th>
                            <th>Montant HT</th>
                            <th>Montant TTC</th>
                        </tr>
                        <tr v-for="Product,n in Catégory.produits" :key="n">
                            <td>{{Product.name}}</td>
                            <td>{{parseFloat(Product.quantiteByUnity).toFixed(2)}}</td>
                            <td>{{Product.escompte}} </td>
                            <td>{{Product.totalPrice}} </td>
                            <td>{{Product.totalPriceTTC}} </td>
                        </tr>
                    </table>
                </div>

                    <table>
                        <tr>
                            <th>Montant total HT</th>
                            <th>Montant total TTC</th>
                        </tr>
                        <tr>
                            <!-- <td>{{ CatégoryHave.Pesticides.produits.map(el => parseInt(el.totalPrice) ) }}</td> -->
                            <!-- <td>{{ CatégoryHave.Pesticides.produits.map(el => parseInt(el.totalPriceTTC) ) }}</td> -->

                            <td>{{sumOfHT}}</td>
                            <td>{{sumOfTTC}}</td>
                        </tr>
                    </table>
            </div>

            <div v-if="ModeOperation == 'Recouvrement'">
                <RapportRecouvrement :RapportId="RapportId" :RecouvrementData="CatégoryHave.recouvrement" @closePopup="closePopupMethod" />
            </div>

            <!-- <div class="ZoneTable"  v-else>
                <h2 style="margin:30px 0px;">{{CatégoryHave.description}}</h2>
                    <p>Les produits</p>
                    <table>
                        <tr>
                            <th>Nom</th>
                            <th>Quantité</th>
                            <th>Réduction Commerciale</th>
                        </tr>
                        <tr v-for="Product,n in CatégoryHave.produits" :key="n">
                            <td>{{Product.name}}</td>
                            <td>{{Product.quantite}}</td>
                            <td>{{Product.escompte}} </td>
                        </tr>
                    </table>
            </div> -->

            <div class="QRCODE">
                QR CODE
            </div>

            <div v-if="ModeOperation === 'Vente'">
                <div class="DownlodPdf" v-for="Catégory,n in CatégoryHave" :key="n">
                    <button @click="DowloadPdf(Catégory.produits[0].categorie)">Telecharger PDF : {{Catégory.produits[0].categorie}}</button>
                </div>
            </div>

            <div v-if="ModeOperation === 'Avoir'">
                <div class="DownlodPdf" v-for="Catégory,n in CatégoryHave" :key="n">
                    <button @click="DowloadPdfAvoir(Catégory.produits[0].categorie)">Telecharger PDF (Avoir) : {{Catégory.produits[0].categorie}}</button>
                </div>
            </div>

            <div class="DownlodPdf"   v-if="ModeOperation !== 'Vente' && ModeOperation !== 'Avoir'">
                <button @click="DowloadPdfNoteHaveCategory()">Telecharger PDF </button>
            </div>
    </div>
  </div>
</template>

<script>

import Swal from 'sweetalert2'
import axios from "axios";
import API_URL from "../../config";
import RapportRecouvrement from "../components/RapportRecouvrement.vue";

export default {
    props:["RapportId"],
    components: {
       RapportRecouvrement
    },
  data() {
    return {
        CatégoryHave:[],
        Id:'',
        ForId:'',
        ModeOperation:'',

        sumOfHT: 0,
        sumOfTTC: 0
    };
  },
  created() {
;
  },
  computed: {},
  methods: {
    closePopupMethod() {
        this.$emit('closePopup2')
    },
    GetRapportData(){
            axios.get(`${API_URL}/rapport/getOne/${this.RapportId}`).then((response) => {
                this.Id = response.data._id,
                this.ForId = response.data.for._id
                this.ModeOperation = response.data.ModeOperation
                this.CatégoryHave = response.data.Rapport

                if(this.CatégoryHave.Pesticides && this.CatégoryHave.Pesticides.produits) {
                    this.CatégoryHave.Pesticides.produits.map(el => this.sumOfHT += parseFloat(el.totalPrice))
                    this.CatégoryHave.Pesticides.produits.map(el => this.sumOfTTC += parseFloat(el.totalPriceTTC))
                }
                else if(this.CatégoryHave.Engraisspeciaux && this.CatégoryHave.Engraisspeciaux.produits) {
                    this.CatégoryHave.Engraisspeciaux.produits.map(el => this.sumOfHT += parseFloat(el.totalPrice))
                    this.CatégoryHave.Engraisspeciaux.produits.map(el => this.sumOfTTC += parseFloat(el.totalPriceTTC))
                }
                else if(this.CatégoryHave.Engraisclassiques && this.CatégoryHave.Engraisclassiques.produits) {
                    this.CatégoryHave.Engraisclassiques.produits.map(el => this.sumOfHT += parseFloat(el.totalPrice))
                    this.CatégoryHave.Engraisclassiques.produits.map(el => this.sumOfTTC += parseFloat(el.totalPriceTTC))
                }
                else if(this.CatégoryHave.Semences && this.CatégoryHave.Semences.produits) {
                    this.CatégoryHave.Semences.produits.map(el => this.sumOfHT += parseFloat(el.totalPrice))
                    this.CatégoryHave.Semences.produits.map(el => this.sumOfTTC += parseFloat(el.totalPriceTTC))
                }
                else if(this.CatégoryHave.Hygiènepublique && this.CatégoryHave.Hygiènepublique.produits) {
                    this.CatégoryHave.Hygiènepublique.produits.map(el => this.sumOfHT += parseFloat(el.totalPrice))
                    this.CatégoryHave.Hygiènepublique.produits.map(el => this.sumOfTTC += parseFloat(el.totalPriceTTC))
                }
                else if(this.CatégoryHave.Additifs && this.CatégoryHave.Additifs.produits) {
                    this.CatégoryHave.Additifs.produits.map(el => this.sumOfHT += parseFloat(el.totalPrice))
                    this.CatégoryHave.Additifs.produits.map(el => this.sumOfTTC += parseFloat(el.totalPriceTTC))
                }
            });
    },
    DowloadPdf(Ctég){
        console.log(Ctég,this.Id,this.ForId)
                // let Shor_Catég = ''
                // if(Ctég == "Engraisclassiques")
                //     Shor_Catég  = 'EC'
                // else if (Ctég == "Engraisspeciaux")
                //     Shor_Catég  = 'ES'
                // else if (Ctég == "Semences")
                //     Shor_Catég  = 'S'
                // else if (Ctég == "Pesticides")
                //     Shor_Catég  = 'P'
                // else if (Ctég == "Hygiènepublique")
                //     Shor_Catég  = 'HP'
                // else if (Ctég == "Additifs")
                //     Shor_Catég  = 'V'

                window.open(`${API_URL}/PDF/DownloadRapportFromBack/${this.Id}/${this.ForId}/${Ctég}/F`, '_blank');
    },
    DowloadPdfAvoir(Ctég) {
        console.log(Ctég,this.Id,this.ForId)
                // let Shor_Catég = ''
                // if(Ctég == "Engraisclassiques")
                //     Shor_Catég  = 'EC'
                // else if (Ctég == "Engraisspeciaux")
                //     Shor_Catég  = 'ES'
                // else if (Ctég == "Semences")
                //     Shor_Catég  = 'S'
                // else if (Ctég == "Pesticides")
                //     Shor_Catég  = 'P'
                // else if (Ctég == "Hygiènepublique")
                //     Shor_Catég  = 'HP'
                // else if (Ctég == "Additifs")
                //     Shor_Catég  = 'V'

                window.open(`${API_URL}/PDF/DownloadRapportFromBackAvoir/${this.Id}/${this.ForId}/${Ctég}/F/Avoir`, '_blank');
    },
    DowloadPdfNoteHaveCategory(){
        window.open(`${API_URL}/PDF/DownloadRapportFromBackRecouvrement/${this.Id}/${this.ForId}/P/F`, '_blank');
        console.log(this.Id)
    }
  },
  mounted(){
      this.GetRapportData()
  }
};
</script>
&a
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #058B84;
.RapportDeVente{
    height: 800px;
    overflow-y: scroll;
    width:50%;
    left: 30%;
    position: absolute;
    z-index: 100;
    background: linear-gradient(180deg, #f3f3f3 30%, #dfdfdf 100%);
    border-radius: 10px;
    box-shadow: 0px 0px 11px -4px;
    text-align: center;
    padding:20px;
    top: 5%;
    // @media (max-width: 1600px) {
    //     height: 500px;
    // }
    h2,h1{
        font-family:$primaryFont ;
        font-size: 25px;
        font-weight:600;
        color:$primary-background-color;
    }
    h1{
        font-size: 40px !important;
        font-weight: bold;
    }
    p{
        font-size: 25px !important;
        font-weight: 600;
        text-align: start;
        line-height: 30px !important;
    }
    .ZoneTable{
        margin-bottom: 40px;
        p {
            color: #058B84;
        }
    }
    table{
        width:100%;
        th{
            background-color:#058B84;
            font-size: 20px;
            color:white;
            padding:10px 0px;
        }
        td{
            font-size: 20px;
            color:#058B84;
            padding:10px 0px;
            border: 1px solid #058B84;
            font-weight: bold;
        }
    }
    .QRCODE{
        padding: 40px;
    }
    .DownlodPdf{
        button{
            padding:10px 40px;
            border-radius: 30px;
            font-weight: bold;
            background: linear-gradient(90deg, rgba(5,139,132,1) 30%, rgba(0,0,0,1) 100%);
            color: #FFFFFF;
            display:block !important;
            margin: 10px auto;
            &:hover{
                background: transparent;
                color:#00abc4;
                border:1px solid #00abc4;
            }

        }
    }
}
</style>
