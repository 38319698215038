<template>
        <div class="leftSideNav" :style="isMax ? 'width: 17%;' : 'width: 5%;'">
      <!-- <v-card style="height: 100vh">
        <v-navigation-drawer
          v-model="drawer"
          :mini-variant.sync="mini"
          permanent
        >
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img
                src="https://searchcept.com/IMAGES/ARD/logo.png"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-title>searchcrm</v-list-item-title>

            <v-btn icon @click.stop="mini = !mini">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item>

          <v-divider></v-divider>

          <v-list dense>
            <v-list-item v-for="item in items" :key="item.title" link>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content @click="reddirectTo(item.url)">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card> -->

      <div class="sideBar">
        <div class="arrow" style="width: 100%; border-radius: 50px;" @click="isMax = !isMax">
          <p v-if="isMax"></p>
          <img src="@/assets/ardsvg/Arrow.svg" :style="isMax ? 'transform: rotate(180deg);' : 'transform: rotate(0deg);'" />
        </div>
        <div class="sideBarContents">
          <div class="icons">
            <div v-for="(item, index) in items" :key="index" class="marginB">
              <router-link :to="item.url" :class="$route.path == item.url ? 'activeIcon icon' : 'icon'">
                <!-- $route.path  activeIcon -->
                <div class="image">
                  <img :src="require('./../assets/ardsvg/'+item.icon)" />
                </div>
                <p v-if="isMax">{{item.title}}</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<style lang="scss">
// .v-image__image--cover {
//   background-size: contain !important;
// }
$grayColorOne: #707070;
$grayColorTwo: #F3F3F3;
$redColor: #FF0000;

  .sideBar {
    margin-top: 66px;
    .arrow {
      background-color: #00ABC4;
      padding: 13px;
      box-shadow: 1px 3px 6px -1px $grayColorOne;
      height: 50px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      transition: all .3s ease-in-out;
      p {
        color: white;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 3px;
        transition: opacity .3s ease-in-out;
      }
      img {
        transition: transform .3s ease-in-out;
      }
    }
    .sideBarContents {
      background: linear-gradient(180deg, rgba(243,243,243,1) 50%, rgba(223,223,223,1) 100%);
      height: 75%;
      margin-top: 10%;
      border-radius: 30px;
      position: absolute;
      width: 100%;
      overflow: auto;
      overflow-x: hidden;
      @media(max-width: 1800px) {
        height: 85%;
      }
      a {
        text-decoration: unset;
      }
      .icons {
        padding: 10px 15px;
        .marginB {
          &:not(:last-of-type) {
            margin-bottom: 8px;
          }
        }
        .activeIcon {
          background-color: #D2D2D2;
        }
        .icon {
          padding: 5px 0px 0px 10px;
          border-radius: 30px;
          transition: all .3s ease-in-out;
          &:hover {
            background-color: #D2D2D2;
            letter-spacing: 1px;
          }
          .image {
            border: 1px solid #8A8D8D;
            border-radius: 50%;
            padding: 5px;
            img {
              width: 25px;
              height: 25px;
            }
          }
          display: flex;
          flex-direction: row;
          align-items: baseline;
          p {
            color: $grayColorOne;
            font-weight: 500;
            width: 100%;
            margin-left: 10%;
            font-size: 14px;
          }
        }
      }
    }
  }
  .leftSideNav {
    float: left;
    position: relative;
    height: 100vh;
    margin-left: 3%;
    transition: width .3s ease-in-out;
    @media(max-width: 990px) {
      display: none;
    }
  }
</style>

<script>
export default {
    data() {
        return {
            drawer: true,
            items: [
              { title: 'Tableau de bord', icon: 'Tableaudeboard.svg', url: `/chartline` },
              { title: 'Gestion des opérateurs', icon: 'Gestiondesoperation.svg', url: `/userlists` },
              // { title: 'Evaluation', icon: 'Suividestock.svg', url: `/operationsLists` },
              { title: 'Timeline', icon: 'Suividestock2.svg', url: `/magasine` },
              { title: 'Liste des clients', icon: 'Listdesclients.svg', url: `/Clients` },
              { title: 'Liste des produits', icon: 'Listdesproduit.svg', url: `/Product` },
              { title: 'Gestion de stock', icon: 'Gestiondesstock.svg', url: `/GestiondeStock` },
              { title: 'Facturation', icon: 'Facturation.svg', url: `/Facturation` },
              { title: 'Recouvrement', icon: 'Facturation.svg', url: `/Recouvrement` },
              { title: 'Dépôt', icon: 'Depot.svg', url: `/Depot` },
              { title: 'Gestion des opérations', icon: 'Params.svg', url: `/GestiondesOperation` },
              { title: 'Gestion des opérations (autres)', icon: 'Params.svg', url: `/GestiondesOperation2` },
              { title: 'Transfert de stock', icon: 'Params2.svg', url: `/TransferDeStock` },
              { title: 'Mouvement des dépôts', icon: 'Params2.svg', url: `/DParP` },
              { title: 'Mouvement des produits', icon: 'Params2.svg', url: `/PParD` },

              { title: 'Liste des ventes journalières', icon: 'Params2.svg', url: `/LVJ` },
              { title: 'Journal comptable des ventes', icon: 'Params2.svg', url: `/JVC` },
              { title: 'Etat des encaissements', icon: 'Params2.svg', url: `/EE` },

              { title: 'Entrée de stock', icon: 'Params2.svg', url: `/addStock` },
              { title: 'Frais de société', icon: 'Params2.svg', url: `/companyFees` },

              { title: 'Gestion des Essais', icon: 'Params2.svg', url: `/GestionStockVE` },
            ],
            isMax: true,
            }
        },
        methods: {
          reddirectTo(val) {
            this.$router.push(`${val}`);
          }
        },
    }
</script>
