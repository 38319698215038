<template>
  <div>
    <div
      class="BalckPage"
      v-if="
        ValideFacture ||
        OpenRapportVente ||
        OpenBonLivraison ||
        OpenRapportApresLivraison
      "
      @click="RemoveAllPopup"
    ></div>
    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />
    <RapportVente v-if="OpenRapportVente" :RapportId="RapportId" />
    <BonLivraison v-if="OpenBonLivraison" :RapportId="RapportId" />
    <RapportApresFacturation
      v-if="OpenRapportApresLivraison"
      :RapportId="RapportId"
    />
    <ValideBeforeFacture
      v-if="ValideFacture"
      :RapportId="RapportId"
      @Close="ClosePopUp()"
    />

    <div class="GestionStock">
      <div class="inputList">
        <v-select
          :items="filterByItems"
          label="Filtrer par"
          solo
          style="width: 20%; float: left"
          v-model="selectedFilterByItems"
          @change="FunctionToGetAllRapportBeforeFacture"
          v-if="BeforeFacture"
        ></v-select>

        <v-text-field
          label="Filtrer par client"
          placeholder="Entrer le nom de client"
          style="width: 20%; float: left"
          v-model="clientName"
          @keyup="FunctionToGetAllRapportBeforeFacture"
          v-if="BeforeFacture"
          solo
        ></v-text-field>

        <v-select
          :items="allTcpByName"
          label="Filtrer par"
          solo
          style="width: 20%"
          v-model="selectedFilterTCP"
          @change="functionToGetAllRapportByOneTCP"
          v-if="BonLivraison"
        ></v-select>

        <v-text-field
          label="Filtrer par client"
          placeholder="Entrer le nom de client (BL)"
          style="width: 20%; float: left"
          v-model="clientName"
          @keyup="functionToGetAllRapportByOneTCP"
          v-if="BonLivraison"
          solo
        ></v-text-field>

        <v-select
          :items="allTcpByName"
          label="Filtrer par"
          solo
          style="width: 20%"
          v-model="selectedFilterTCP"
          @change="functionToGetAllRapportByOneTCPAvoir"
          v-if="BonR"
        ></v-select>

        <v-text-field
          label="Filtrer par client"
          placeholder="Entrer le nom de client (BR)"
          style="width: 20%; float: left"
          v-model="clientName"
          @keyup="functionToGetAllRapportByOneTCPAvoir"
          v-if="BonR"
          solo
        ></v-text-field>

        <v-select
          :items="filterByItems"
          label="Filtrer par"
          solo
          style="width: 20%"
          v-model="selectedFilterByItemsAferF"
          @change="FunctionToGetAllRapportAfterFacture"
          v-if="AfterFacture"
        ></v-select>

        <v-text-field
          label="Filtrer par client"
          placeholder="Entrer le nom de client"
          style="width: 20%; float: left"
          v-model="clientName"
          @keyup="FunctionToGetAllRapportAfterFacture"
          v-if="AfterFacture"
          solo
        ></v-text-field>
      </div>

      <div class="GestionStock__Btn">
        <h2 :class="BeforeFacture ? 'Active' : ''" @click="SwitchBtn('Before')">
          Rapports avant facturation
        </h2>
        <h2 :class="BonLivraison ? 'Active' : ''" @click="SwitchBtn('Bon')">
          BL
        </h2>
        <h2 :class="BonR ? 'Active' : ''" @click="SwitchBtn('BR')">BR</h2>
        <h2 :class="AfterFacture ? 'Active' : ''" @click="SwitchBtn('After')">
          Rapports après facturation
        </h2>
      </div>
      <div class="BeforeFacture" v-if="BeforeFacture">
        <div v-for="(Rapport, n) in GetAllRapportBeforeFacturation" :key="n">
          <div
            class="SingleFacturation"
            :style="
              n % 2
                ? 'background: linear-gradient(180deg, #f3f3f3 50%, #dfdfdf 100%);'
                : ''
            "
          >
            <div
              class="Informatation"
              @click="(RapportId = Rapport._id), (OpenRapportVente = true)"
            >
              <h2>
                {{ Rapport.InfoGenerale.NomSociete }}
                {{ Rapport.RBCisOpened ? "-" + Rapport.RBC : "" }}
              </h2>
              <p>{{ Rapport.ModeOperation }} / {{ Rapport.CommercialName }}</p>
              <p>{{ Rapport.InfoGenerale.Adress }}</p>
              <p>{{ Rapport.CurrentDate }} - {{ Rapport.CurrentTime }}</p>
            </div>
            <div class="Btn" v-if="Rapport.EtatMagasinier == 'N'">
              <button
                class="Valider"
                @click="(RapportId = Rapport._id), (ValideFacture = true)"
              >
                Valider
              </button>
              <button
                class="Annuler"
                @click="AnnulerRapportBeforeFacture(Rapport._id)"
              >
                Annuler
              </button>
            </div>
            <div class="Btn" v-if="Rapport.EtatMagasinier == 'A'">
              <button class="ValiderBtn">Annulée</button>
            </div>
            <div class="Btn" v-if="Rapport.EtatMagasinier == 'V'">
              <button class="ValiderBtn">Validé</button>
              <svg
                @click="seeRapportData(Rapport._id)"
                xmlns="http://www.w3.org/2000/svg"
                height="44px"
                viewBox="0 0 24 24"
                width="44px"
                fill="#058B84"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div
          v-for="(numberOfPaginationList, key) in parseInt(
            numberOfPaginationList
          )"
          :key="key"
        >
          <div
            class="pagination"
            v-if="currentPaginationList == numberOfPaginationList"
          >
            <div class="leftArrow">
              <v-btn
                class="mx-2 mr-10"
                fab
                dark
                large
                color="#058B84"
                @click="prevPaginationList"
                v-if="currentPaginationList > 1"
                ><v-icon>mdi-chevron-double-left</v-icon>
              </v-btn>
            </div>
            <button
              class="paginationBtn"
              :class="
                currentPagination == n + 10 * (numberOfPaginationList - 1)
                  ? 'activePagination'
                  : ''
              "
              @click="paginationFunc(n - 1 + 10 * (numberOfPaginationList - 1))"
              v-for="n in numberOfPages <= 10
                ? numberOfPages
                : skippedPaginationNumber"
              :key="n + 10 * (numberOfPaginationList - 1)"
            >
              {{ n + 10 * (numberOfPaginationList - 1) }}
            </button>
            <div class="rigthArrow">
              <v-btn
                class="mx-2 ml-10"
                fab
                dark
                large
                color="#058B84"
                @click="nextPaginationList"
                v-if="
                  currentPaginationList != parseInt(sumNumberOfPaginationList)
                "
                ><v-icon>mdi-chevron-double-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <div class="BL" v-if="BonLivraison">
        <div
          class="SingleFacturation"
          v-for="(Bon, n) in GetAllBonLivraison"
          :key="n"
          @click="(RapportId = Bon._id), (OpenBonLivraison = true)"
          :style="n % 2 ? 'background-color:#f3e6da' : ''"
        >
          <div class="Informatation">
            <h2>{{ Bon.InfoGenerale.NomSociete }} - {{ Bon.RBL }}</h2>
            <p>{{ Bon.ModeOperation }} / {{ Bon.CommercialName }}</p>
            <p>{{ Bon.InfoGenerale.Adress }}</p>
            <p>{{ Bon.CurrentDate }} - {{ Bon.CurrentTime }}</p>
          </div>
          <div class="Btn">
            <button class="Valider ValideBL">Valider</button>
          </div>
        </div>

        <div v-if="selectedFilterTCP == 'Tous'">
          <div
            v-for="(numberOfPaginationList, key) in parseInt(
              numberOfPaginationList
            )"
            :key="key"
          >
            <div
              class="pagination"
              v-if="currentPaginationList == numberOfPaginationList"
            >
              <div class="leftArrow">
                <v-btn
                  class="mx-2 mr-10"
                  fab
                  dark
                  large
                  color="#058B84"
                  @click="prevPaginationList"
                  v-if="currentPaginationList > 1"
                  ><v-icon>mdi-chevron-double-left</v-icon>
                </v-btn>
              </div>
              <button
                class="paginationBtn"
                :class="
                  currentPagination == n + 10 * (numberOfPaginationList - 1)
                    ? 'activePagination'
                    : ''
                "
                @click="
                  paginationFuncBL(n - 1) + 10 * (numberOfPaginationList - 1)
                "
                v-for="n in numberOfPages <= 10
                  ? numberOfPages
                  : skippedPaginationNumber"
                :key="n + 10 * (numberOfPaginationList - 1)"
              >
                {{ n + 10 * (numberOfPaginationList - 1) }}
              </button>
              <div class="rigthArrow">
                <v-btn
                  class="mx-2 ml-10"
                  fab
                  dark
                  large
                  color="#058B84"
                  @click="nextPaginationList"
                  v-if="
                    currentPaginationList != parseInt(sumNumberOfPaginationList)
                  "
                  ><v-icon>mdi-chevron-double-right</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedFilterTCP != 'Tous'">
          <div
            v-for="(numberOfPaginationList, key) in parseInt(
              numberOfPaginationList
            )"
            :key="key"
          >
            <div
              class="pagination"
              v-if="currentPaginationList == numberOfPaginationList"
            >
              <div class="leftArrow">
                <v-btn
                  class="mx-2 mr-10"
                  fab
                  dark
                  large
                  color="#058B84"
                  @click="prevPaginationList"
                  v-if="currentPaginationList > 1"
                  ><v-icon>mdi-chevron-double-left</v-icon>
                </v-btn>
              </div>
              <button
                class="paginationBtn"
                :class="
                  currentPagination == n + 10 * (numberOfPaginationList - 1)
                    ? 'activePagination'
                    : ''
                "
                @click="
                  paginationFuncBLOneTCP(n - 1) +
                    10 * (numberOfPaginationList - 1)
                "
                v-for="n in numberOfPages <= 10
                  ? numberOfPages
                  : skippedPaginationNumber"
                :key="n + 10 * (numberOfPaginationList - 1)"
              >
                {{ n + 10 * (numberOfPaginationList - 1) }}
              </button>
              <div class="rigthArrow">
                <v-btn
                  class="mx-2 ml-10"
                  fab
                  dark
                  large
                  color="#058B84"
                  @click="nextPaginationList"
                  v-if="
                    currentPaginationList != parseInt(sumNumberOfPaginationList)
                  "
                  ><v-icon>mdi-chevron-double-right</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="BR" v-if="BonR">
        <div
          class="SingleFacturation"
          v-for="(Bon, n) in GetAllBonReception"
          :key="n"
          @click="(RapportId = Bon._id), (OpenBonLivraison = true)"
          :style="n % 2 ? 'background-color:#f3e6da' : ''"
        >
          <div class="Informatation">
            <h2>{{ Bon.InfoGenerale.NomSociete }} - {{ Bon.RBL }}</h2>
            <p>{{ Bon.ModeOperation }} / {{ Bon.CommercialName }}</p>
            <p>{{ Bon.InfoGenerale.Adress }}</p>
            <p>{{ Bon.CurrentDate }} - {{ Bon.CurrentTime }}</p>
          </div>
          <div class="Btn">
            <button class="Valider ValideBL">Valider</button>
          </div>
        </div>

        <div v-if="selectedFilterTCP == 'Tous'">
          <div
            v-for="(numberOfPaginationList, key) in parseInt(
              numberOfPaginationList
            )"
            :key="key"
          >
            <div
              class="pagination"
              v-if="currentPaginationList == numberOfPaginationList"
            >
              <div class="leftArrow">
                <v-btn
                  class="mx-2 mr-10"
                  fab
                  dark
                  large
                  color="#058B84"
                  @click="prevPaginationList"
                  v-if="currentPaginationList > 1"
                  ><v-icon>mdi-chevron-double-left</v-icon>
                </v-btn>
              </div>
              <button
                class="paginationBtn"
                :class="
                  currentPagination == n + 10 * (numberOfPaginationList - 1)
                    ? 'activePagination'
                    : ''
                "
                @click="
                  paginationFuncBR(n - 1) + 10 * (numberOfPaginationList - 1)
                "
                v-for="n in numberOfPages <= 10
                  ? numberOfPages
                  : skippedPaginationNumber"
                :key="n + 10 * (numberOfPaginationList - 1)"
              >
                {{ n + 10 * (numberOfPaginationList - 1) }}
              </button>
              <div class="rigthArrow">
                <v-btn
                  class="mx-2 ml-10"
                  fab
                  dark
                  large
                  color="#058B84"
                  @click="nextPaginationList"
                  v-if="
                    currentPaginationList != parseInt(sumNumberOfPaginationList)
                  "
                  ><v-icon>mdi-chevron-double-right</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedFilterTCP != 'Tous'">
          <div
            v-for="(numberOfPaginationList, key) in parseInt(
              numberOfPaginationList
            )"
            :key="key"
          >
            <div
              class="pagination"
              v-if="currentPaginationList == numberOfPaginationList"
            >
              <div class="leftArrow">
                <v-btn
                  class="mx-2 mr-10"
                  fab
                  dark
                  large
                  color="#058B84"
                  @click="prevPaginationList"
                  v-if="currentPaginationList > 1"
                  ><v-icon>mdi-chevron-double-left</v-icon>
                </v-btn>
              </div>
              <button
                class="paginationBtn"
                :class="
                  currentPagination == n + 10 * (numberOfPaginationList - 1)
                    ? 'activePagination'
                    : ''
                "
                @click="
                  paginationFuncBROneTCP(n - 1) +
                    10 * (numberOfPaginationList - 1)
                "
                v-for="n in numberOfPages <= 10
                  ? numberOfPages
                  : skippedPaginationNumber"
                :key="n + 10 * (numberOfPaginationList - 1)"
              >
                {{ n + 10 * (numberOfPaginationList - 1) }}
              </button>
              <div class="rigthArrow">
                <v-btn
                  class="mx-2 ml-10"
                  fab
                  dark
                  large
                  color="#058B84"
                  @click="nextPaginationList"
                  v-if="
                    currentPaginationList != parseInt(sumNumberOfPaginationList)
                  "
                  ><v-icon>mdi-chevron-double-right</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="AfterFacturation" v-if="AfterFacture">
        <div
          class="SingleFacturation"
          v-for="(Rapport, n) in GetALLRapportAfterFacturation"
          :key="n"
          :style="n % 2 ? 'background-color:#f3e6da' : ''"
        >
          <div
            class="Informatation"
            @click="
              (RapportId = Rapport._id), (OpenRapportApresLivraison = true)
            "
          >
            <h2>{{ Rapport.InfoGenerale.NomSociete }} - {{ Rapport.RF }}</h2>
            <p>{{ Rapport.ModeOperation }} / {{ Rapport.CommercialName }}</p>
            <p>{{ Rapport.InfoGenerale.Adress }}</p>
            <p>{{ Rapport.CurrentDate }} / {{ Rapport.CurrentTime }}</p>
          </div>
          <div class="Btn" v-if="Rapport.EtatMagasinierTwo == 'N'">
            <button
              class="Valider"
              @click="ValideRapportAfterFacturation(Rapport._id)"
            >
              Valider
            </button>
            <button
              class="Annuler"
              @click="AnnulerRapportAfterFacturation(Rapport._id)"
            >
              Annuler
            </button>
          </div>
          <div class="Btn" v-if="Rapport.EtatMagasinierTwo == 'A'">
            <button class="Annuler AnnulerBtn">Annulée</button>
          </div>
          <div class="Btn" v-if="Rapport.EtatMagasinierTwo == 'V'">
            <button class="ValiderBtn">Validé</button>
          </div>
        </div>

        <div
          v-for="(numberOfPaginationList, key) in parseInt(
            numberOfPaginationList
          )"
          :key="key"
        >
          <div
            class="pagination"
            v-if="currentPaginationList == numberOfPaginationList"
          >
            <div class="leftArrow">
              <v-btn
                class="mx-2 mr-10"
                fab
                dark
                large
                color="#058B84"
                @click="prevPaginationList"
                v-if="currentPaginationList > 1"
                ><v-icon>mdi-chevron-double-left</v-icon>
              </v-btn>
            </div>
            <button
              class="paginationBtn"
              :class="
                currentPagination == n + 10 * (numberOfPaginationList - 1)
                  ? 'activePagination'
                  : ''
              "
              @click="
                paginationFuncAfterFacturation(n - 1) +
                  10 * (numberOfPaginationList - 1)
              "
              v-for="n in numberOfPages <= 10
                ? numberOfPages
                : skippedPaginationNumber"
              :key="n + 10 * (numberOfPaginationList - 1)"
            >
              {{ n + 10 * (numberOfPaginationList - 1) }}
            </button>
            <div class="rigthArrow">
              <v-btn
                class="mx-2 ml-10"
                fab
                dark
                large
                color="#058B84"
                @click="nextPaginationList"
                v-if="
                  currentPaginationList != parseInt(sumNumberOfPaginationList)
                "
                ><v-icon>mdi-chevron-double-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import RapportVente from "../components/RapportVente.vue";
import BonLivraison from "../components/BonDeLivraison.vue";
import RapportApresFacturation from "../components/RapportApresFacturation.vue";
import ValideBeforeFacture from "../components/ValideBeforeFacture.vue";

import Swal from "sweetalert2";

import axios from "axios";
import API_URL from "../../config";
import SOCKETS_URL from "../../configSocketsURL";
import io from "socket.io-client";
import Vue from "vue";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
    RapportVente,
    BonLivraison,
    RapportApresFacturation,
    ValideBeforeFacture,
  },
  data() {
    return {
      BeforeFacture: true,
      BonLivraison: false,
      AfterFacture: false,
      BonR: false,
      OpenRapportVente: false,
      OpenBonLivraison: false,
      OpenRapportApresLivraison: false,
      ValideFacture: false,
      GetAllRapportBeforeFacturation: [],
      GetAllBonLivraison: [],
      GetALLRapportAfterFacturation: [],
      GetAllBonReception: [],
      RapportId: "",
      filterByItems: ["Tous", "Annulé", "Validé", "En cours", "Avoir"],
      selectedFilterByItems: "Tous",
      selectedFilterByItemsAferF: "Tous",
      selectedFilterTCP: "Tous",
      allTcpByName: ["Tous"],
      sockets: io.connect(`${SOCKETS_URL}`),
      numberOfPages: 1,
      numberOfPaginationList: 2,
      sumNumberOfPaginationList: 0,

      skippedPaginationNumber: 10,
      currentPagination: 1,
      currentPaginationList: 1,

      ID: "",
      FromUser: "",
      HaveBL: false,
      HaveBC: false,
      HaveF: false,
      ES: false,
      EC: false,
      S: false,
      P: false,
      HP: false,
      A: false,
      MP: "",
      MG: [],
      getTheData: false,

      clientName: "",
    };
  },
  created() {
    // this.sockets.on('refreshBcData', (BonCommandeID) => {
    //     axios.get(`${API_URL}/api/rapport/getOne/${BonCommandeID}`).then(response => {
    //         axios.get(`${API_URL}/api/PDF/DownloadRapportFromBack/${BonCommandeID}/${response.data.for._id}/HP/BC`).then(() => {
    //             io.emit('refreshGestionDesOperationData', rapportID, response.data.for._id)
    //         })
    //     })
    // })
    // this.sockets.on('refreshGestionDesOperationData', () => {
    //   this.FunctionToGetAllRapportBeforeFacture()
    // })
    this.sockets.on("getAllRapportBeforeFacture", (data) => {
      this.FunctionToGetAllRapportBeforeFacture();
    });
    this.sockets.on("getAllBL", () => {
      this.functionToGetAllRapportByOneTCP();
    });
    this.sockets.on("getAllBR", () => {
      this.functionToGetAllRapportByOneTCPAvoir();
    });
    this.sockets.on("getAllRapportAfterFacture", (data) => {
      this.FunctionToGetAllRapportAfterFacture();
    });
  },
  computed: {},

  methods: {
    seeRapportData(rapportID) {
      let listOfUrl = [];

      axios.put(`${API_URL}/rapport/bcIsOpenedStatus/${rapportID}`).then(() => {
        axios
          .get(`${API_URL}/rapport/getSumOffQuantityOffOneR/${rapportID}`)
          .then((response) => {
            this.HaveBL = response.data.HaveBL;
            this.HaveBC = response.data.HaveBC;
            this.HaveF = response.data.HaveF;
            this.ES = response.data.ES;
            this.EC = response.data.EC;
            this.S = response.data.S;
            this.P = response.data.P;
            this.HP = response.data.HP;
            this.A = response.data.A;
            this.MP = response.data.MP;
            this.MG = response.data.MG;

            this.ID = response.data.ID;
            this.FromUser = response.data.FromUser;

            this.getTheData = true;

            if (this.HaveBC) {
              if (this.ES) {
                listOfUrl.push(
                  `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/ES/BC?indexOf=1`
                );
              }
              if (this.EC) {
                listOfUrl.push(
                  `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/EC/BC?indexOf=2`
                );
              }
              if (this.S) {
                listOfUrl.push(
                  `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/S/BC?indexOf=3`
                );
              }
              if (this.P) {
                listOfUrl.push(
                  `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/P/BC?indexOf=4`
                );
              }
              if (this.HP) {
                listOfUrl.push(
                  `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/HP/BC?indexOf=5`
                );
              }
              if (this.A) {
                listOfUrl.push(
                  `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/V/BC?indexOf=6`
                );
              }
            }
            if (this.HaveBL) {
              this.MG.map((city, index) => {
                listOfUrl.push(
                  `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/${city}/BL?indexOf=${index}`
                );
              });
            }
          })
          .then(() => {
            listOfUrl.map((el) => {
              window.open(el, "_blank");
            });
          })
          .then(() => {
            this.sockets.emit("validateRapportMagasinier", rapportID, "V");
          });
      });
    },

    paginationFuncBR(skiped) {
      axios
        .get(`${API_URL}/rapport/getAllBr?skiped=${skiped * 10}&limit=10`)
        .then((response) => {
          this.GetAllBonReception = response.data;
          this.currentPagination = skiped + 1;
        });
    },
    paginationFuncBROneTCP(skiped) {
      axios.get(`${API_URL}/user/getUserNameWithID/all`).then((response) => {
        let getTcpIDFromName = response.data.filter(
          (element) => element.Name == this.selectedFilterTCP
        );
        let TcpID = getTcpIDFromName[0]._id;

        axios
          .get(
            `${API_URL}/rapport/getAllBrForOneTcp/TCP/${TcpID}?skiped=${
              skiped * 10
            }&limit=10`
          )
          .then((response) => {
            this.GetAllBonReception = response.data;

            axios
              .get(`${API_URL}/rapport/countAllBRForOneTcp/TCP/${TcpID}`)
              .then((response) => {
                this.numberOfPagesAndPagination(response.data / 10);
              });
          });
      });
    },

    paginationFuncBL(skiped) {
      axios
        .get(`${API_URL}/rapport/getAllBl?skiped=${skiped * 10}&limit=10`)
        .then((response) => {
          this.GetAllBonLivraison = response.data;
          this.currentPagination = skiped + 1;
        });
    },

    paginationFuncBLOneTCP(skiped) {
      axios.get(`${API_URL}/user/getUserNameWithID/all`).then((response) => {
        let getTcpIDFromName = response.data.filter(
          (element) => element.Name == this.selectedFilterTCP
        );
        let TcpID = getTcpIDFromName[0]._id;

        axios
          .get(
            `${API_URL}/rapport/getAllBlForOneTcp/TCP/${TcpID}?skiped=${
              skiped * 10
            }&limit=10`
          )
          .then((response) => {
            this.GetAllBonLivraison = response.data;
            this.currentPagination = skiped + 1;
            axios
              .get(`${API_URL}/rapport/countAllBlForOneTcp/TCP/${TcpID}`)
              .then((response) => {
                this.numberOfPagesAndPagination(response.data / 10);
              });
          });
      });
    },

    paginationFunc(skiped) {
      // skiped += this.currentPaginationList-1
      // console.log("currentPaginationList", this.currentPaginationList)
      // console.log("skiped", skiped)

      // alert(skiped)
      if (this.selectedFilterByItems == "Annulé") {
        axios
          .get(
            `${API_URL}/rapport/getRapportBeforF?skiped=${
              skiped * 10
            }&limit=10&etatMagasin=A`
          )
          .then((response) => {
            this.GetAllRapportBeforeFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      } else if (this.selectedFilterByItems == "Validé") {
        axios
          .get(
            `${API_URL}/rapport/getRapportBeforF?skiped=${
              skiped * 10
            }&limit=10&etatMagasin=V`
          )
          .then((response) => {
            this.GetAllRapportBeforeFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      } else if (this.selectedFilterByItems == "En cours") {
        axios
          .get(
            `${API_URL}/rapport/getRapportBeforF?skiped=${
              skiped * 10
            }&limit=10&etatMagasin=N`
          )
          .then((response) => {
            this.GetAllRapportBeforeFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      } else if (this.selectedFilterByItems == "Avoir") {
        axios
          .get(
            `${API_URL}/rapport/getRapportBeforF?skiped=${
              skiped * 10
            }&limit=10&MP=Avoir`
          )
          .then((response) => {
            this.GetAllRapportBeforeFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      } else {
        axios
          .get(
            `${API_URL}/rapport/getRapportBeforF?skiped=${skiped * 10}&limit=10`
          )
          .then((response) => {
            // this.GetAllRapportBeforeFacturation.push(response.data);
            this.GetAllRapportBeforeFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      }
    },

    paginationFuncAfterFacturation(skiped) {
      if (this.selectedFilterByItemsAferF == "Annulé") {
        axios
          .get(
            `${API_URL}/rapport/getRapportAfterF?skiped=${
              skiped * 10
            }&limit=10&etatMagasinTwo=A`
          )
          .then((response) => {
            this.GetALLRapportAfterFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      } else if (this.selectedFilterByItemsAferF == "Validé") {
        axios
          .get(
            `${API_URL}/rapport/getRapportAfterF?skiped=${
              skiped * 10
            }&limit=10&etatMagasinTwo=V`
          )
          .then((response) => {
            this.GetALLRapportAfterFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      } else if (this.selectedFilterByItemsAferF == "En cours") {
        axios
          .get(
            `${API_URL}/rapport/getRapportAfterF?skiped=${
              skiped * 10
            }&limit=10&etatMagasinTwo=N`
          )
          .then((response) => {
            this.GetALLRapportAfterFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      } else if (this.selectedFilterByItemsAferF == "Avoir") {
        axios
          .get(
            `${API_URL}/rapport/getRapportAfterF?skiped=${
              skiped * 10
            }&limit=10&MP=Avoir`
          )
          .then((response) => {
            this.GetALLRapportAfterFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      } else {
        axios
          .get(
            `${API_URL}/rapport/getRapportAfterF?skiped=${skiped * 10}&limit=10`
          )
          .then((response) => {
            this.GetALLRapportAfterFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      }
    },

    numberOfPagesAndPagination(numberOfPages) {
      this.numberOfPages = Math.ceil(numberOfPages);
      this.numberOfPaginationList =
        this.numberOfPages / this.skippedPaginationNumber;
      this.sumNumberOfPaginationList =
        this.numberOfPages / this.skippedPaginationNumber;

      if (parseInt(this.numberOfPaginationList) == 0) {
        this.numberOfPaginationList = 1;
        this.sumNumberOfPaginationList = 1;
      }
    },
    RemoveAllPopup() {
      this.OpenRapportVente = false;
      this.OpenBonLivraison = false;
      this.OpenRapportApresLivraison = false;
      this.ValideFacture = false;
    },
    SwitchBtn(Condition) {
      this.BeforeFacture = false;
      this.AfterFacture = false;
      this.BonLivraison = false;
      this.BonR = false;
      if (Condition === "Before") {
        this.FunctionToGetAllRapportBeforeFacture();
        this.BeforeFacture = true;
      } else if (Condition === "Bon") {
        this.FuntionToGetAllBonLivraison();
        this.BonLivraison = true;
      } else if (Condition === "BR") {
        this.FuntionToGetAllBonReception();
        this.BonR = true;
      } else if (Condition === "After") {
        this.FunctionToGetAllRapportAfterFacture();
        this.AfterFacture = true;
      }
    },

    FunctionToGetAllRapportBeforeFacture() {
      // this.currentPagination = 1
      // this.currentPaginationList = 1

      if (this.selectedFilterByItems == "Tous") {
        axios
          .get(
            `${API_URL}/rapport/countRapportBefaureF?clientName=${this.clientName}`
          )
          .then((response) => {
            this.numberOfPagesAndPagination(response.data / 10);
          });
        axios
          .get(
            `${API_URL}/rapport/getRapportBeforF?skiped=0&limit=10&clientName=${this.clientName}`
          )
          .then((response) => {
            this.GetAllRapportBeforeFacturation = response.data;
          });
      } else {
        if (this.selectedFilterByItems == "Annulé") {
          axios
            .get(
              `${API_URL}/rapport/countRapportBefaureF?etatMagasin=A&clientName=${this.clientName}`
            )
            .then((response) => {
              this.numberOfPagesAndPagination(response.data / 10);
            });
          axios
            .get(
              `${API_URL}/rapport/getRapportBeforF?skiped=0&limit=10&etatMagasin=A&clientName=${this.clientName}`
            )
            .then((response) => {
              this.GetAllRapportBeforeFacturation = response.data;
            });
        } else if (this.selectedFilterByItems == "Validé") {
          axios
            .get(
              `${API_URL}/rapport/countRapportBefaureF?etatMagasin=V&clientName=${this.clientName}`
            )
            .then((response) => {
              this.numberOfPagesAndPagination(response.data / 10);
            });
          axios
            .get(
              `${API_URL}/rapport/getRapportBeforF?skiped=0&limit=10&etatMagasin=V&clientName=${this.clientName}`
            )
            .then((response) => {
              this.GetAllRapportBeforeFacturation = response.data;
            });
        } else if (this.selectedFilterByItems == "En cours") {
          axios
            .get(
              `${API_URL}/rapport/countRapportBefaureF?etatMagasin=N&clientName=${this.clientName}`
            )
            .then((response) => {
              this.numberOfPagesAndPagination(response.data / 10);
            });
          axios
            .get(
              `${API_URL}/rapport/getRapportBeforF?skiped=0&limit=10&etatMagasin=N&clientName=${this.clientName}`
            )
            .then((response) => {
              this.GetAllRapportBeforeFacturation = response.data;
            });
        } else if (this.selectedFilterByItems == "Avoir") {
          axios
            .get(
              `${API_URL}/rapport/countRapportBefaureF?MP=Avoir&clientName=${this.clientName}`
            )
            .then((response) => {
              this.numberOfPagesAndPagination(response.data / 10);
            });
          axios
            .get(
              `${API_URL}/rapport/getRapportBeforF?skiped=0&limit=10&MP=Avoir&clientName=${this.clientName}`
            )
            .then((response) => {
              this.GetAllRapportBeforeFacturation = response.data;
            });
        }
      }
    },

    functionToGetAllRapportByOneTCP() {
      this.currentPagination = 1;
      this.currentPaginationList = 1;

      if (this.selectedFilterTCP == "Tous") {
        this.FuntionToGetAllBonLivraison();
      } else {
        axios.get(`${API_URL}/user/getUserNameWithID/all`).then((response) => {
          let getTcpIDFromName = response.data.filter(
            (element) => element.Name == this.selectedFilterTCP
          );
          let TcpID = getTcpIDFromName[0]._id;

          axios
            .get(
              `${API_URL}/rapport/getAllBlForOneTcp/TCP/${TcpID}?skiped=0&limit=10&clientName=${this.clientName}`
            )
            .then((response) => {
              this.GetAllBonLivraison = response.data;

              axios
                .get(
                  `${API_URL}/rapport/countAllBlForOneTcp/TCP/${TcpID}?clientName=${this.clientName}`
                )
                .then((response) => {
                  this.numberOfPagesAndPagination(response.data / 10);
                });
            });
        });
      }
    },

    functionToGetAllRapportByOneTCPAvoir() {
      this.currentPagination = 1;
      this.currentPaginationList = 1;

      if (this.selectedFilterTCP == "Tous") {
        this.FuntionToGetAllBonReception();
      } else {
        axios.get(`${API_URL}/user/getUserNameWithID/all`).then((response) => {
          let getTcpIDFromName = response.data.filter(
            (element) => element.Name == this.selectedFilterTCP
          );
          let TcpID = getTcpIDFromName[0]._id;

          axios
            .get(
              `${API_URL}/rapport/getAllBrForOneTcp/TCP/${TcpID}?skiped=0&limit=10&clientName=${this.clientName}`
            )
            .then((response) => {
              this.GetAllBonReception = response.data;

              axios
                .get(
                  `${API_URL}/rapport/countAllBRForOneTcp/TCP/${TcpID}?clientName=${this.clientName}`
                )
                .then((response) => {
                  this.numberOfPagesAndPagination(response.data / 10);
                });
            });
        });
      }
    },

    FunctionToGetAllRapportAfterFacture() {
      this.currentPagination = 1;
      this.currentPaginationList = 1;

      if (this.selectedFilterByItemsAferF == "Tous") {
        axios
          .get(
            `${API_URL}/rapport/getRapportAfterF?skiped=0&limit=10&clientName=${this.clientName}`
          )
          .then((response) => {
            this.GetALLRapportAfterFacturation = response.data;
          });
        axios
          .get(
            `${API_URL}/rapport/countRapportAfterF?clientName=${this.clientName}`
          )
          .then((response) => {
            this.numberOfPagesAndPagination(response.data / 10);
          });
      } else if (this.selectedFilterByItemsAferF == "Validé") {
        axios
          .get(
            `${API_URL}/rapport/getRapportAfterF?skiped=0&limit=10&etatMagasinTwo=V&clientName=${this.clientName}`
          )
          .then((response) => {
            this.GetALLRapportAfterFacturation = response.data;
          });
        axios
          .get(
            `${API_URL}/rapport/countRapportAfterF?etatMagasinTwo=V&clientName=${this.clientName}`
          )
          .then((response) => {
            this.numberOfPagesAndPagination(response.data / 10);
          });
      } else if (this.selectedFilterByItemsAferF == "Annulé") {
        axios
          .get(
            `${API_URL}/rapport/getRapportAfterF?skiped=0&limit=10&etatMagasinTwo=A&clientName=${this.clientName}`
          )
          .then((response) => {
            this.GetALLRapportAfterFacturation = response.data;
          });
        axios
          .get(
            `${API_URL}/rapport/countRapportAfterF?etatMagasinTwo=A&clientName=${this.clientName}`
          )
          .then((response) => {
            this.numberOfPagesAndPagination(response.data / 10);
          });
      } else if (this.selectedFilterByItemsAferF == "En cours") {
        axios
          .get(
            `${API_URL}/rapport/getRapportAfterF?skiped=0&limit=10&etatMagasinTwo=N&clientName=${this.clientName}`
          )
          .then((response) => {
            this.GetALLRapportAfterFacturation = response.data;
          });
        axios
          .get(
            `${API_URL}/rapport/countRapportAfterF?etatMagasinTwo=N&clientName=${this.clientName}`
          )
          .then((response) => {
            this.numberOfPagesAndPagination(response.data / 10);
          });
      } else if (this.selectedFilterByItemsAferF == "Avoir") {
        axios
          .get(
            `${API_URL}/rapport/getRapportAfterF?skiped=0&limit=10&MP=Avoir&clientName=${this.clientName}`
          )
          .then((response) => {
            this.GetALLRapportAfterFacturation = response.data;
          });
        axios
          .get(
            `${API_URL}/rapport/countRapportAfterF&MP=Avoir&clientName=${this.clientName}`
          )
          .then((response) => {
            this.numberOfPagesAndPagination(response.data / 10);
          });
      }
    },
    AnnulerRapportBeforeFacture(Id) {
      axios
        .put(`${API_URL}/rapport/changeState/${Id}/Magasinier/A`)
        .then((response) => {
          // console.log(response)
          this.FunctionToGetAllRapportBeforeFacture();
          this.sockets.emit("validateRapportMagasinier", Id, "A");
        });
    },

    FuntionToGetAllBonLivraison() {
      this.currentPagination = 1;
      this.currentPaginationList = 1;
      axios
        .get(
          `${API_URL}/rapport/getAllBl?skiped=0&limit=10&clientName=${this.clientName}`
        )
        .then((response) => {
          this.GetAllBonLivraison = response.data;
        });
      axios
        .get(`${API_URL}/rapport/countRapportBL?clientName=${this.clientName}`)
        .then((response) => {
          this.numberOfPagesAndPagination(response.data / 10);
        });
    },

    FuntionToGetAllBonReception() {
      this.currentPagination = 1;
      this.currentPaginationList = 0;
      axios
        .get(
          `${API_URL}/rapport/getAllBr?skiped=0&limit=10&clientName=${this.clientName}`
        )
        .then((response) => {
          this.GetAllBonReception = response.data;
        });
      axios
        .get(`${API_URL}/rapport/countRapportBR?clientName=${this.clientName}`)
        .then((response) => {
          this.numberOfPagesAndPagination(response.data / 10);
        });
    },
    ValideRapportAfterFacturation(Id) {
      this.$swal({
        title: "Voulez vous valider?",
        confirmButtonText: `Valider`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .put(`${API_URL}/rapport/changeState/${Id}/Magasinier2/V`)
            .then((response) => {
              this.FunctionToGetAllRapportAfterFacture();
            });

          this.$swal("cette operation a été validée", "", "success");
        }
      });
    },
    AnnulerRapportAfterFacturation(Id) {
      this.$swal({
        title: "Voulez vous annuler?",
        confirmButtonText: `Annuler`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .put(`${API_URL}/rapport/changeState/${Id}/Magasinier2/A`)
            .then((response) => {
              this.FunctionToGetAllRapportAfterFacture();
            });

          this.$swal("cette operation a été annulée", "", "success");
        }
      });

      // console.log(Id);
    },
    ClosePopUp() {
      this.ValideFacture = false;
      this.FunctionToGetAllRapportBeforeFacture();
    },

    getAllTcpByName() {
      axios.get(`${API_URL}/user/getUserNameWithID/all`).then((response) => {
        return response.data.map((element) => {
          this.allTcpByName.push(element.Name);
        });
      });
    },

    prevPaginationList() {
      this.currentPaginationList -= 1;
    },

    nextPaginationList() {
      this.currentPaginationList += 1;
    },
  },
  async mounted() {
    await this.FunctionToGetAllRapportBeforeFacture();
    await this.getAllTcpByName();
    // await this.FuntionToGetAllBonLivraison();
    // await this.FunctionToGetAllRapportAfterFacture();
    // await this.FuntionToGetAllBonReception();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;
.BalckPage {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 2%;
  .leftArrow {
    button {
      width: 40px;
      height: 40px;
    }
  }
  .rigthArrow {
    button {
      width: 40px;
      height: 40px;
    }
  }
  .paginationBtn {
    background-color: $primaryColor;
    color: white;
    font-size: 20px;
    padding: 3px;
    border-radius: 50%;
    width: 40px;
    margin: 0px 4px;
    text-align: center;
  }
  .activePagination {
    background-color: $primaryColor2 !important;
  }
}
.GestionStock {
  .inputList {
    display: flex;
    div {
      padding: 5%;
    }
  }
  width: 70%;
  float: left;
  margin-left: 5%;
  padding: 2% 0% 0%;
  border-radius: 20px;
  background-color: #f2f2f2;
  ::-webkit-scrollbar {
    width: 5px;
    position: relative;
    top: 20px;
    border-radius: 30px;
  }
  /* Track */

  ::-webkit-scrollbar-track {
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
  }
  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #b41206;
    border-radius: 10px;
  }
  .GestionStock__Btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 3px solid rgb(205, 205, 205);
    padding: 0% 7%;
    h2 {
      width: 100%;
      text-align: center;
      padding: 10px 0px;
      cursor: pointer;
      margin: 0px;
      font-size: 20px;
      background-color: #d1e6e5;
      transition: all 0.2s ease-in-out;
      color: #058b84;
      &:first-of-type {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      &:hover {
        background-color: #afc5c4;
        color: white;
      }
    }

    .Active {
      background-color: #058b84;
      color: white;
    }
  }
  .BeforeFacture,
  .BL,
  .AfterFacturation,
  .BR {
    margin-top: 50px;
    height: 600px;
    overflow-y: scroll;
    margin: 0px auto;
    margin-top: 50px;
    width: 100%;
    .Informatation {
      width: 100%;
      h2 {
        text-transform: uppercase;
      }
    }
    .SingleFacturation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 30px;
      box-shadow: 5px 5px 11px -8px black;
      width: 90%;
      margin: 20px auto;
      background: linear-gradient(180deg, #f3f3f3 50%, #dfdfdf 100%);
      h2 {
        font-size: 25px;
        color: #058b84;
        font-weight: 400;
        padding-bottom: 15px;
      }
      p {
        line-height: 10px;
        font-weight: 400;
        line-height: 30px;
        color: #8a8d8d;
      }
      .Btn {
        display: flex;
        align-items: center;
        button {
          padding: 10px 30px;
          margin: 0px 30px;
          border-radius: 5px;
          font-size: 17px;
          font-weight: bold;
          color: white;
        }
        .ValiderBtn {
          border: 1px solid gray;
          background-color: white;
          color: gray;
        }
        .Valider {
          background-color: green;
          &:hover {
            border: 1px solid green;
            color: green;
            background-color: transparent;
          }
        }
        .Annuler {
          background-color: red;
          &:hover {
            border: 1px solid red;
            color: red;
            background-color: transparent;
          }
        }
      }
    }
  }
  .ValideBL {
    &:hover {
      background-color: green !important;
      color: white !important;
      border: none !important;
    }
  }
  .AnnulerBtn {
    &:hover {
      background-color: red !important;
      color: white !important;
      border: none !important;
    }
  }
  // .ValiderBtn{
  //     &:hover{
  //         background-color:green !important;
  //         color: white !important;
  //         border: none !important;
  //     }
  // }
}
</style>
