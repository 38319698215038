<!-- this.currentPagination = 1
this.currentPaginationList = 1 -->

<template>
  <div>
    <div
      class="BalckPage"
      v-if="OpenRapportVente || OpenValidationRecouvrement"
      @click="RemoveAllPopup"
    ></div>
    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />
    <RapportVente
      v-if="OpenRapportVente"
      :RapportId="RapportId"
      @closePopup2="OpenRapportVente = false"
    />
    <ValidationRecouvrement v-if="OpenValidationRecouvrement" />

    <div class="GestionStock">
      <div class="inputList">
        <v-select
          :items="filterByItems"
          label="Filtrer par"
          solo
          style="width: 20%; float: left"
          v-model="selectedFilterByItems"
          @change="FunctionToGetAllRapportMoroccoFacturation"
          v-if="BeforeFacture"
        ></v-select>

        <v-text-field
          label="Filtrer par client"
          placeholder="Entrer le nom de client"
          style="width: 20%; float: left"
          v-model="clientName"
          @keyup="FunctionToGetAllRapportMoroccoFacturation"
          v-if="BeforeFacture"
          solo
        ></v-text-field>

        <v-select
          :items="filterByItems"
          label="Filtrer par"
          solo
          style="width: 20%; float: left"
          v-model="selectedFilterByItemsBeriut"
          @change="FunctionToGetAllRapportBeirutFacturation"
          v-if="BonLivraison"
        ></v-select>

        <v-text-field
          label="Filtrer par client"
          placeholder="Entrer le nom de client"
          style="width: 20%; float: left"
          v-model="clientName"
          @keyup="FunctionToGetAllRapportBeirutFacturation"
          v-if="BonLivraison"
          solo
        ></v-text-field>

        <v-select
          :items="filterByItems"
          label="Filtrer par"
          solo
          style="width: 20%; float: left"
          v-model="selectedFilterByItemsRecouvremment"
          @change="FunctionToGetAllRapportVisiteDeRecouvrement"
          v-if="AfterFacture"
        ></v-select>

        <v-text-field
          label="Filtrer par client"
          placeholder="Entrer le nom de client"
          style="width: 20%; float: left"
          v-model="clientName"
          @keyup="FunctionToGetAllRapportVisiteDeRecouvrement"
          v-if="AfterFacture"
          solo
        ></v-text-field>
      </div>

      <div class="GestionStock__Btn">
        <h2 :class="BeforeFacture ? 'Active' : ''" @click="SwitchBtn('Before')">
          Maroc
        </h2>
        <h2 :class="BonLivraison ? 'Active' : ''" @click="SwitchBtn('Bon')">
          Beyrouth
        </h2>
        <h2 :class="AfterFacture ? 'Active' : ''" @click="SwitchBtn('After')">
          Validation de recouvrement
        </h2>
      </div>
      <div class="BeforeFacture" v-if="BeforeFacture">
        <div
          class="SingleFacturation"
          v-for="(Rapport, n) in GetAllMoroccoFacturation"
          :key="n"
          :style="
            n % 2
              ? 'background: linear-gradient(180deg, #f3f3f3 50%, #dfdfdf 100%);'
              : ''
          "
        >
          <div
            class="Informatation"
            @click="(RapportId = Rapport._id), (OpenRapportVente = true)"
          >
            <h2>{{ Rapport.InfoGenerale.NomSociete }} - {{ Rapport.RF }}</h2>
            <p>{{ Rapport.ModeOperation }} / {{ Rapport.CommercialName }}</p>
            <p>{{ Rapport.InfoGenerale.Adress }}</p>
            <p>{{ Rapport.CurrentDate }} / {{ Rapport.CurrentTime }}</p>
          </div>
          <div class="Btn" v-if="Rapport.EtatFacturation == 'N'">
            <button
              class="Valider"
              @click="
                Rapport.ModeOperation == 'Vente'
                  ? ValideMoroccoFacturation(
                      Rapport._id,
                      Rapport.InfoGenerale.ClientCode
                    )
                  : ValideMoroccoFacturationAvoir(
                      Rapport._id,
                      Rapport.InfoGenerale.ClientCode
                    )
              "
              v-if="!disabledValidateFBtn"
            >
              Valider
            </button>

            <button v-if="disabledValidateFBtn || disabledAnnulerFBtn">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </button>

            <button
              class="Annuler"
              @click="
                Rapport.ModeOperation == 'Vente'
                  ? AnnulerMoroccoFacturation(Rapport._id)
                  : AnnulerMoroccoFacturationAvoir(Rapport._id)
              "
              v-if="!disabledAnnulerFBtn"
            >
              Annuler
            </button>
          </div>
          <div class="Btn" v-if="Rapport.EtatFacturation == 'V'">
            <button class="ValiderBtn">Validé</button>
          </div>
          <div class="Btn" v-if="Rapport.EtatFacturation == 'A'">
            <button class="ValiderBtn">Annulée</button>
          </div>
        </div>

        <div
          v-for="(numberOfPaginationList, key) in parseInt(
            numberOfPaginationList
          )"
          :key="key"
        >
          <div
            class="pagination"
            v-if="currentPaginationList == numberOfPaginationList"
          >
            <div class="leftArrow">
              <v-btn
                class="mx-2 mr-10"
                fab
                dark
                large
                color="#058B84"
                @click="prevPaginationList"
                v-if="currentPaginationList > 1"
                ><v-icon>mdi-chevron-double-left</v-icon>
              </v-btn>
            </div>
            <button
              class="paginationBtn"
              :class="
                currentPagination == n + 10 * (numberOfPaginationList - 1)
                  ? 'activePagination'
                  : ''
              "
              @click="
                paginationFuncF(n - 1 + 10 * (numberOfPaginationList - 1))
              "
              v-for="n in numberOfPages <= 10
                ? numberOfPages
                : skippedPaginationNumber"
              :key="n + 10 * (numberOfPaginationList - 1)"
            >
              {{ n + 10 * (numberOfPaginationList - 1) }}
            </button>
            <div class="rigthArrow">
              <v-btn
                class="mx-2 ml-10"
                fab
                dark
                large
                color="#058B84"
                @click="nextPaginationList"
                v-if="
                  currentPaginationList != parseInt(sumNumberOfPaginationList)
                "
                ><v-icon>mdi-chevron-double-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="BL" v-if="BonLivraison">
        <div
          class="SingleFacturation"
          v-for="(Rapport, n) in GetAllBeirutFacturation"
          :key="n"
          :style="n % 2 ? 'background-color:#f3e6da' : ''"
        >
          <div
            class="Informatation"
            @click="(RapportId = Rapport._id), (OpenRapportVente = true)"
          >
            <h2>{{ Rapport.InfoGenerale.NomSociete }} - {{ Rapport.RF }}</h2>
            <p>{{ Rapport.ModeOperation }} / {{ Rapport.CommercialName }}</p>
            <p>{{ Rapport.InfoGenerale.Adress }}</p>
            <p>{{ Rapport.CurrentDate }} / {{ Rapport.CurrentTime }}</p>
          </div>
          <div class="Btn" v-if="Rapport.EtatValidationB == 'N'">
            <button
              class="Valider"
              @click="ValideAnuulerRapportBeirut(Rapport._id, 'V')"
            >
              Valider
            </button>
            <!-- <button class="Annuler" @click="ValideAnuulerRapportBeirut(Rapport._id, 'A')">Annuler</button> -->
          </div>
          <div class="Btn" v-if="Rapport.EtatValidationB == 'V'">
            <button class="ValiderBtn">Validé</button>
          </div>
        </div>

        <div
          v-for="(numberOfPaginationList, key) in parseInt(
            numberOfPaginationList
          )"
          :key="key"
        >
          <div
            class="pagination"
            v-if="currentPaginationList == numberOfPaginationList"
          >
            <div class="leftArrow">
              <v-btn
                class="mx-2 mr-10"
                fab
                dark
                large
                color="#058B84"
                @click="prevPaginationList"
                v-if="currentPaginationList > 1"
                ><v-icon>mdi-chevron-double-left</v-icon>
              </v-btn>
            </div>
            <button
              class="paginationBtn"
              :class="
                currentPagination == n + 10 * (numberOfPaginationList - 1)
                  ? 'activePagination'
                  : ''
              "
              @click="
                paginationFuncB(n - 1 + 10 * (numberOfPaginationList - 1))
              "
              v-for="n in numberOfPages <= 10
                ? numberOfPages
                : skippedPaginationNumber"
              :key="n + 10 * (numberOfPaginationList - 1)"
            >
              {{ n + 10 * (numberOfPaginationList - 1) }}
            </button>
            <div class="rigthArrow">
              <v-btn
                class="mx-2 ml-10"
                fab
                dark
                large
                color="#058B84"
                @click="nextPaginationList"
                v-if="
                  currentPaginationList != parseInt(sumNumberOfPaginationList)
                "
                ><v-icon>mdi-chevron-double-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="AfterFacturation" v-if="AfterFacture">
        <div
          class="SingleFacturation"
          v-for="(Rapport, n) in GetAllRapportVisiteDeRecouvrement"
          :key="n"
          :style="n % 2 ? 'background-color:#f3e6da' : ''"
        >
          <div
            class="Informatation"
            @click="(RapportId = Rapport._id), (OpenRapportVente = true)"
          >
            <h2>{{ Rapport.InfoGenerale.NomSociete }}</h2>
            <p>{{ Rapport.ModeOperation }} / {{ Rapport.CommercialName }}</p>
            <p>{{ Rapport.InfoGenerale.Adress }}</p>
            <p>{{ Rapport.CurrentDate }} / {{ Rapport.CurrentTime }}</p>
          </div>
          <div class="Btn" v-if="Rapport.EtatFacturation == 'N'">
            <button
              class="Valider"
              @click="
                ValideAnuulerRapportVisiteDeRecouvrement(Rapport._id, 'V')
              "
            >
              Valider
            </button>
            <button
              class="Annuler"
              @click="
                ValideAnuulerRapportVisiteDeRecouvrement(Rapport._id, 'A')
              "
            >
              Annuler
            </button>
            <!-- <v-switch
              v-model="switch1"
              @change="changeClientStatus(Rapport.InfoGenerale.ClientCode)"
            ></v-switch> -->
          </div>
          <div class="Btn" v-if="Rapport.EtatFacturation == 'V'">
            <button class="ValiderBtn">Validée</button>
            <!-- <v-switch
              v-model="switch1"
              @change="changeClientStatus(Rapport.InfoGenerale.ClientCode)"
            ></v-switch> -->
          </div>
          <div class="Btn" v-if="Rapport.EtatFacturation == 'A'">
            <button class="Annuler AnnulerBtn">Annulée</button>

            <!-- <v-switch
              v-model="switch1"
              @change="changeClientStatus(Rapport.InfoGenerale.ClientCode)"
            ></v-switch> -->
          </div>
        </div>

        <div
          v-for="(numberOfPaginationList, key) in parseInt(
            numberOfPaginationList
          )"
          :key="key"
        >
          <div
            class="pagination"
            v-if="currentPaginationList == numberOfPaginationList"
          >
            <div class="leftArrow">
              <v-btn
                class="mx-2 mr-10"
                fab
                dark
                large
                color="#058B84"
                @click="prevPaginationList"
                v-if="currentPaginationList > 1"
                ><v-icon>mdi-chevron-double-left</v-icon>
              </v-btn>
            </div>
            <button
              class="paginationBtn"
              :class="
                currentPagination == n + 10 * (numberOfPaginationList - 1)
                  ? 'activePagination'
                  : ''
              "
              @click="
                paginationFuncR(n - 1 + 10 * (numberOfPaginationList - 1))
              "
              v-for="n in numberOfPages <= 10
                ? numberOfPages
                : skippedPaginationNumber"
              :key="n + 10 * (numberOfPaginationList - 1)"
            >
              {{ n + 10 * (numberOfPaginationList - 1) }}
            </button>
            <div class="rigthArrow">
              <v-btn
                class="mx-2 ml-10"
                fab
                dark
                large
                color="#058B84"
                @click="nextPaginationList"
                v-if="
                  currentPaginationList != parseInt(sumNumberOfPaginationList)
                "
                ><v-icon>mdi-chevron-double-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";
import RapportVente from "../components/RapportVenteTwo.vue";
import BonLivraison from "../components/BonDeLivraison.vue";
import ValidationRecouvrement from "../components/RapportApresFacturation.vue";
import Vue from "vue";

import Swal from "sweetalert2";

import axios from "axios";
import API_URL from "../../config";
import SOCKETS_URL from "../../configSocketsURL";
import io from "socket.io-client";

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,
    RapportVente,
    ValidationRecouvrement,
  },
  data() {
    return {
      BeforeFacture: true,
      BonLivraison: false,
      AfterFacture: false,
      OpenRapportVente: false,
      OpenValidationRecouvrement: false,
      GetAllMoroccoFacturation: [],
      GetAllBeirutFacturation: [],
      GetAllRapportVisiteDeRecouvrement: [],
      RapportId: "",
      disabledValidateFBtn: false,
      disabledAnnulerFBtn: false,

      selectedProducts: [],

      filterByItems: ["Tous", "Annulé", "Validé", "En cours", "Avoir"],
      selectedFilterByItems: "Tous",
      selectedFilterByItemsBeriut: "Tous",
      selectedFilterByItemsRecouvremment: "Tous",

      sockets: io.connect(`${SOCKETS_URL}`),

      numberOfPages: 1,
      numberOfPaginationList: 2,
      sumNumberOfPaginationList: 0,

      skippedPaginationNumber: 10,
      currentPagination: 1,
      currentPaginationList: 1,

      ID: "",
      FromUser: "",
      HaveBL: false,
      HaveBC: false,
      HaveF: false,
      ES: false,
      EC: false,
      S: false,
      P: false,
      HP: false,
      A: false,
      MP: "",
      MG: [],
      getTheData: false,

      clientName: "",
    };
  },
  created() {
    this.sockets.on("getAllFacturationMaroc", (data) => {
      this.FunctionToGetAllRapportMoroccoFacturation();
    });

    this.sockets.on("getAllFacturationBirot", (data) => {
      this.FunctionToGetAllRapportBeirutFacturation();
    });

    this.sockets.on("getAllFacturationRecouvremment", (data) => {
      this.FunctionToGetAllRapportVisiteDeRecouvrement();
    });

    this.sockets.on("getAllFacturationBirotOnlyData", () => {
      this.FunctionToGetAllRapportBeirutFacturation();
    });

    this.sockets.on("getAllFacturationMarocOnlyData", () => {
      this.FunctionToGetAllRapportMoroccoFacturation();
    });

    this.sockets.on("getAllRecouvremmentDataAfterUpdateClientStatus", () => {
      this.FunctionToGetAllRapportVisiteDeRecouvrement();
    });
  },
  computed: {},
  methods: {
    changeClientStatus(codeClient) {
      // alert(codeClient)
      axios
        .put(
          `${API_URL}/codeClient/WEB/updateClientStatusFromCode/${codeClient}`
        )
        .then((response) => {
          this.$swal(
            `${response.data ? "Disactivé" : "Activé"}`,
            "",
            "success"
          );
        });
    },
    paginationFuncF(skiped) {
      if (this.selectedFilterByItems == "Tous") {
        axios
          .get(`${API_URL}/rapport/getRapporF?skiped=${skiped * 10}&limit=10`)
          .then((response) => {
            this.GetAllMoroccoFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      } else if (this.selectedFilterByItems == "Validé") {
        axios
          .get(
            `${API_URL}/rapport/getRapporF?skiped=${
              skiped * 10
            }&limit=10&etatFacturation=V`
          )
          .then((response) => {
            this.GetAllMoroccoFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      } else if (this.selectedFilterByItems == "Annulé") {
        axios
          .get(
            `${API_URL}/rapport/getRapporF?skiped=${
              skiped * 10
            }&limit=10&etatFacturation=A`
          )
          .then((response) => {
            this.GetAllMoroccoFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      } else if (this.selectedFilterByItems == "En cours") {
        axios
          .get(
            `${API_URL}/rapport/getRapporF?skiped=${
              skiped * 10
            }&limit=10&etatFacturation=N`
          )
          .then((response) => {
            this.GetAllMoroccoFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      } else if (this.selectedFilterByItems == "Avoir") {
        axios
          .get(
            `${API_URL}/rapport/getRapporF?skiped=${
              skiped * 10
            }&limit=10&MP=Avoir`
          )
          .then((response) => {
            this.GetAllMoroccoFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      }
    },

    paginationFuncB(skiped) {
      if (this.selectedFilterByItemsBeriut == "Tous") {
        axios
          .get(
            `${API_URL}/rapport/getRapporBurot?skiped=${skiped * 10}&limit=10`
          )
          .then((response) => {
            this.GetAllBeirutFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      } else if (this.selectedFilterByItemsBeriut == "Validé") {
        axios
          .get(
            `${API_URL}/rapport/getRapporBurot?skiped=${
              skiped * 10
            }&limit=10&etatValidationB=V`
          )
          .then((response) => {
            this.GetAllBeirutFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      } else if (this.selectedFilterByItemsBeriut == "Annulé") {
        axios
          .get(
            `${API_URL}/rapport/getRapporBurot?skiped=${
              skiped * 10
            }&limit=10&etatValidationB=A`
          )
          .then((response) => {
            this.GetAllBeirutFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      } else if (this.selectedFilterByItemsBeriut == "En cours") {
        axios
          .get(
            `${API_URL}/rapport/getRapporBurot?skiped=${
              skiped * 10
            }&limit=10&etatValidationB=N`
          )
          .then((response) => {
            this.GetAllBeirutFacturation = response.data;
            this.currentPagination = skiped + 1;
          });
      }
    },

    paginationFuncR(skiped) {
      axios
        .get(
          `${API_URL}/rapport/getRapporValidationDeRecouvrement?skiped=${
            skiped * 10
          }&limit=10&rapportStatus=${
            this.selectedFilterByItemsRecouvremment == "Validé"
              ? "V"
              : this.selectedFilterByItemsRecouvremment == "Annulé"
              ? "A"
              : this.selectedFilterByItemsRecouvremment == "En cours"
              ? "N"
              : "Tous"
          }`
        )
        .then((response) => {
          this.GetAllRapportVisiteDeRecouvrement = response.data;
          this.currentPagination = skiped + 1;
        });
    },

    numberOfPagesAndPagination(numberOfPages) {
      this.numberOfPages = Math.ceil(numberOfPages);
      this.numberOfPaginationList =
        this.numberOfPages / this.skippedPaginationNumber;
      this.sumNumberOfPaginationList =
        this.numberOfPages / this.skippedPaginationNumber;

      if (parseInt(this.numberOfPaginationList) == 0) {
        this.numberOfPaginationList = 1;
        this.sumNumberOfPaginationList = 1;
      }
    },

    prevPaginationList() {
      this.currentPaginationList -= 1;
    },

    nextPaginationList() {
      this.currentPaginationList += 1;
    },

    activateOrDesactivateClient(userCodeID, rapportID) {
      axios
        .put(
          `${API_URL}/codeClient/WEB/updateClientStatusFromCode/${userCodeID}`
        )
        .then((response) => {
          let ObjectSendit = {
            EnableDiableClient: response.data,
          };
          axios
            .put(`${API_URL}/rapport/updateRD/${rapportID}`, ObjectSendit)
            .then((responsetwo) => {
              // console.log(responsetwo.data)
              Vue.$toast.open({
                message: response.data,
                type: "success",
              });
            });
          // alert(response.data)
        });
    },
    getThisRapportSumary(IDvalue) {
      // window.open(`https://www.geeksforgeeks.org/`, '_blank')
      // window.open(`https://www.google.co.uk/`, '_blank')

      let listOfUrl = [];

      axios
        .get(`${API_URL}/rapport/getSumOffQuantityOffOneR/${IDvalue}`)
        .then((response) => {
          this.HaveBL = response.data.HaveBL;
          this.HaveBC = response.data.HaveBC;
          this.HaveF = response.data.HaveF;
          this.ES = response.data.ES;
          this.EC = response.data.EC;
          this.S = response.data.S;
          this.P = response.data.P;
          this.HP = response.data.HP;
          this.A = response.data.A;
          this.MP = response.data.MP;
          this.MG = response.data.MG;

          this.ID = response.data.ID;
          this.FromUser = response.data.FromUser;

          this.getTheData = true;

          if (this.HaveBC) {
            if (this.ES) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/ES/BC?indexOf=1`
              );
            }
            if (this.EC) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/EC/BC?indexOf=2`
              );
            }
            if (this.S) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/S/BC?indexOf=3`
              );
            }
            if (this.P) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/P/BC?indexOf=4`
              );
            }
            if (this.HP) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/HP/BC?indexOf=5`
              );
            }
            if (this.A) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/V/BC?indexOf=6`
              );
            }
          }
          if (this.HaveBL) {
            this.MG.map((city, index) => {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/${city}/BL?indexOf=${index}`
              );
            });
          }
          if (this.HaveF) {
            if (this.ES) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/ES/F?indexOf=1`
              );
            }
            if (this.EC) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/EC/F?indexOf=2`
              );
            }
            if (this.S) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/S/F?indexOf=3`
              );
            }
            if (this.P) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/P/F?indexOf=4`
              );
            }
            if (this.HP) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/HP/F?indexOf=5`
              );
            }
            if (this.A) {
              listOfUrl.push(
                `${API_URL}/PDF/DownloadRapportFromBack/${this.ID}/${this.FromUser}/V/F?indexOf=6`
              );
            }
          }
        })
        .then(() => {
          listOfUrl.map((el) => {
            window.open(el, "_blank");
            this.sockets.emit("openRapportAtValidate");
          });
        });
    },
    SpeechMethode(data) {
      Vue.$toast.open({
        message: data,
        type: "success",
        duration: 5000,
        pauseOnHover: true,
      });

      var speech = new SpeechSynthesisUtterance();

      // Set the text and voice attributes.
      speech.text = data;
      speech.volume = 1;
      speech.rate = 0.5;
      speech.pitch = 0;
      speech.lang = "fr";

      window.speechSynthesis.speak(speech);
    },
    RemoveAllPopup() {
      this.OpenRapportVente = false;
      this.OpenValidationRecouvrement = false;
    },
    SwitchBtn(Condition) {
      this.resetePaggination();

      this.BeforeFacture = false;
      this.AfterFacture = false;
      this.BonLivraison = false;
      if (Condition === "Before") {
        (this.filterByItems = [
          "Tous",
          "Annulé",
          "Validé",
          "En cours",
          "Avoir",
        ]),
          this.FunctionToGetAllRapportMoroccoFacturation();
        this.BeforeFacture = true;
      } else if (Condition === "Bon") {
        (this.filterByItems = ["Tous", "Annulé", "Validé", "En cours"]),
          this.FunctionToGetAllRapportBeirutFacturation();
        this.BonLivraison = true;
      } else if (Condition === "After") {
        (this.filterByItems = ["Tous", "Annulé", "Validé", "En cours"]),
          this.FunctionToGetAllRapportVisiteDeRecouvrement();
        this.AfterFacture = true;
      }
    },
    FunctionToGetAllRapportVisiteDeRecouvrement() {
      axios
        .get(
          `${API_URL}/rapport/countRapportRecouvremment?clientName=${
            this.clientName
          }&rapportStatus=${
            this.selectedFilterByItemsRecouvremment == "Validé"
              ? "V"
              : this.selectedFilterByItemsRecouvremment == "Annulé"
              ? "A"
              : this.selectedFilterByItemsRecouvremment == "En cours"
              ? "N"
              : "Tous"
          }`
        )
        .then((response) => {
          this.numberOfPagesAndPagination(response.data / 10);
        });
      axios
        .get(
          `${API_URL}/rapport/getRapporValidationDeRecouvrement?skiped=0&limit=10&clientName=${
            this.clientName
          }&rapportStatus=${
            this.selectedFilterByItemsRecouvremment == "Validé"
              ? "V"
              : this.selectedFilterByItemsRecouvremment == "Annulé"
              ? "A"
              : this.selectedFilterByItemsRecouvremment == "En cours"
              ? "N"
              : "Tous"
          }`

          // ?rapportStatus=${this.selectedFilterByItemsRecouvremment == "Validé" ? "V" : this.selectedFilterByItemsRecouvremment == "Annulé" ? "A" : "N"}&skiped=${skiped * 10}&limit=10
        )
        .then((response) => {
          axios.post(`${API_URL}/rapport/clearRedisData?keys=["EC"]`);
          this.GetAllRapportVisiteDeRecouvrement = response.data;

          console.log("client data", response.data);
        });
    },

    resetePaggination() {
      this.currentPagination = 1;
      this.currentPaginationList = 1;
    },
    FunctionToGetAllRapportMoroccoFacturation() {
      if (this.selectedFilterByItems == "Tous") {
        axios
          .get(`${API_URL}/rapport/countRapportF?clientName=${this.clientName}`)
          .then((response) => {
            this.numberOfPagesAndPagination(response.data / 10);
          });
        axios
          .get(
            `${API_URL}/rapport/getRapporF?skiped=0&limit=10&clientName=${this.clientName}`
          )
          .then((response) => {
            this.GetAllMoroccoFacturation = response.data;
          });
      } else if (this.selectedFilterByItems == "Validé") {
        axios
          .get(
            `${API_URL}/rapport/countRapportF?etatFacturation=V&clientName=${this.clientName}`
          )
          .then((response) => {
            this.numberOfPagesAndPagination(response.data / 10);
          });
        axios
          .get(
            `${API_URL}/rapport/getRapporF?skiped=0&limit=10&etatFacturation=V&clientName=${this.clientName}`
          )
          .then((response) => {
            this.GetAllMoroccoFacturation = response.data;
          });
      } else if (this.selectedFilterByItems == "Annulé") {
        axios
          .get(
            `${API_URL}/rapport/countRapportF?etatFacturation=A&clientName=${this.clientName}`
          )
          .then((response) => {
            this.numberOfPagesAndPagination(response.data / 10);
          });
        axios
          .get(
            `${API_URL}/rapport/getRapporF?skiped=0&limit=10&etatFacturation=A&clientName=${this.clientName}`
          )
          .then((response) => {
            this.GetAllMoroccoFacturation = response.data;
          });
      } else if (this.selectedFilterByItems == "En cours") {
        axios
          .get(
            `${API_URL}/rapport/countRapportF?etatFacturation=N&clientName=${this.clientName}`
          )
          .then((response) => {
            this.numberOfPagesAndPagination(response.data / 10);
          });
        axios
          .get(
            `${API_URL}/rapport/getRapporF?skiped=0&limit=10&etatFacturation=N&clientName=${this.clientName}`
          )
          .then((response) => {
            this.GetAllMoroccoFacturation = response.data;
          });
      } else if (this.selectedFilterByItems == "Avoir") {
        axios
          .get(
            `${API_URL}/rapport/countRapportF?MP=Avoir&clientName=${this.clientName}`
          )
          .then((response) => {
            this.numberOfPagesAndPagination(response.data / 10);
          });
        axios
          .get(
            `${API_URL}/rapport/getRapporF?skiped=0&limit=10&MP=Avoir&clientName=${this.clientName}`
          )
          .then((response) => {
            this.GetAllMoroccoFacturation = response.data;
          });
      }
    },
    FunctionToGetAllRapportBeirutFacturation() {
      if (this.selectedFilterByItemsBeriut == "Tous") {
        axios
          .get(
            `${API_URL}/rapport/countRapportByrot?clientName=${this.clientName}`
          )
          .then((response) => {
            this.numberOfPagesAndPagination(response.data / 10);
          });
        axios
          .get(
            `${API_URL}/rapport/getRapporBurot?skiped=0&limit=10&clientName=${this.clientName}`
          )
          .then((response) => {
            this.GetAllBeirutFacturation = response.data;
          });
      } else if (this.selectedFilterByItemsBeriut == "Validé") {
        axios
          .get(
            `${API_URL}/rapport/countRapportByrot?etatValidationB=V&clientName=${this.clientName}`
          )
          .then((response) => {
            this.numberOfPagesAndPagination(response.data / 10);
          });
        axios
          .get(
            `${API_URL}/rapport/getRapporBurot?skiped=0&limit=10&etatValidationB=V&clientName=${this.clientName}`
          )
          .then((response) => {
            this.GetAllBeirutFacturation = response.data;
          });
      } else if (this.selectedFilterByItemsBeriut == "Annulé") {
        axios
          .get(
            `${API_URL}/rapport/countRapportByrot?etatValidationB=A&clientName=${this.clientName}`
          )
          .then((response) => {
            this.numberOfPagesAndPagination(response.data / 10);
          });
        axios
          .get(
            `${API_URL}/rapport/getRapporBurot?skiped=0&limit=10&etatValidationB=A&clientName=${this.clientName}`
          )
          .then((response) => {
            this.GetAllBeirutFacturation = response.data;
          });
      } else if (this.selectedFilterByItemsBeriut == "En cours") {
        axios
          .get(
            `${API_URL}/rapport/countRapportByrot?etatValidationB=N&clientName=${this.clientName}`
          )
          .then((response) => {
            this.numberOfPagesAndPagination(response.data / 10);
          });
        axios
          .get(
            `${API_URL}/rapport/getRapporBurot?skiped=0&limit=10&etatValidationB=N&clientName=${this.clientName}`
          )
          .then((response) => {
            this.GetAllBeirutFacturation = response.data;
          });
      }
    },

    async ValideMoroccoFacturation(Id, codeClient) {
      this.$swal({
        title: "Voulez vous valider ?",
        showDenyButton: true,
        confirmButtonText: `Valider`,
        denyButtonText: `Annuler`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          try {
            this.disabledValidateFBtn = true;
            this.selectedProducts = [];

            const res = await axios.get(
              `${API_URL}/rapport/rapportMagasinStock/${Id}`
            );
            res.data.map((data) => {
              this.selectedProducts.push({
                Name: data.productName,
                City: data.city,
                quantity: data.quantity,
              });
            });

            await axios.post(`${API_URL}/rapport/updateRapportStockData/${Id}`)
            await axios.post(`${API_URL}/Stock/updateStock`, {
              selectedProducts: this.selectedProducts,
            });

            Vue.$toast.open({
              message: "Stock Updated",
              type: "success",
            });
            this.disabledValidateFBtn = false;

            await axios.put(
              `${API_URL}/rapport/changeState/${Id}/Facturation/V`
            );

            Vue.$toast.open({
              message: "Validée",
              type: "success",
            });

            await axios.put(`${API_URL}/rapport/updateFcreatedT/${Id}`);

            Vue.$toast.open({
              message: "Time updated + depot <=> produit updated",
              type: "success",
            });

            await axios.put(
              `${API_URL}/rapport/getAndUpdateRapportHaveThisClientComptable/${codeClient}`
            );
            await axios.put(
              `${API_URL}/rapport/addCliendIdToRapportReq/${codeClient}`
            );

            Vue.$toast.open({
              message: "Client data added to rapport",
              type: "success",
            });

            await axios.post(
              `${API_URL}/rapport/clearRedisData?keys=["GestionOP", "LVJ", "ProductByDepotDpP"]`
            );

            Vue.$toast.open({
              message: "Cache is updated",
              type: "success",
            });

            this.FunctionToGetAllRapportMoroccoFacturation();
            this.sockets.emit("validateRapportFacturationMaroc", Id, "V");

            this.$swal("Cette operation a été validée", "", "success");
          } catch (err) {
            this.disabledValidateFBtn = false;
            console.log("plafond error", err.response);
            if (err.response && err.response.data.error) {
              Vue.$toast.open({
                message: err.response.data.error.error,
                type: "error",
              });
            }
          }
        }
      });
    },

    async ValideMoroccoFacturationAvoir(Id, codeClient) {
      this.$swal({
        title: "Voulez vous valider ?",
        showDenyButton: true,
        confirmButtonText: `Valider`,
        denyButtonText: `Annuler`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          try {
            this.disabledValidateFBtn = true;
            this.selectedProducts = [];

            const res = await axios.get(
              `${API_URL}/rapport/rapportMagasinStock/${Id}`
            );
            res.data.map((data) => {
              this.selectedProducts.push({
                Name: data.productName,
                City: data.city,
                quantity: data.quantity,
              });
            });

            await axios.post(`${API_URL}/rapport/updateRapportStockData/${Id}`)
            await axios.post(`${API_URL}/Stock/updateStockAvoir`, {
              selectedProducts: this.selectedProducts,
            });

            Vue.$toast.open({
              message: "Stock Updated (Avoir)",
              type: "success",
            });
            this.disabledValidateFBtn = false;

            await axios.put(
              `${API_URL}/rapport/changeState/${Id}/Facturation/V`
            );

            Vue.$toast.open({
              message: "Validée",
              type: "success",
            });

            await axios.put(`${API_URL}/rapport/updateFcreatedT/${Id}`);

            Vue.$toast.open({
              message: "Time updated",
              type: "success",
            });

            this.FunctionToGetAllRapportMoroccoFacturation();
            this.sockets.emit("validateRapportFacturationMaroc", Id, "V");

            this.$swal("Cette operation a été validée", "", "success");
          } catch (err) {
            this.disabledValidateFBtn = false;
            if (err.response && err.response.data.error) {
              Vue.$toast.open({
                message: err.response.data.error.error,
                type: "error",
              });
            }
          }
        }
      });
    },

    async AnnulerMoroccoFacturation(Id) {
      this.$swal({
        title: "Voulez vous annuler?",
        confirmButtonText: `Annuler`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.disabledAnnulerFBtn = true;
          this.selectedProducts = [];

          await axios
            .get(`${API_URL}/rapport/rapportMagasinStock/${Id}`)
            .then((res) => {
              res.data.map((data) => {
                this.selectedProducts.push({
                  Name: data.productName,
                  City: data.city,
                  quantity: data.quantity,
                });
              });

              axios
                .post(`${API_URL}/Stock/updateStockAvoir`, {
                  selectedProducts: this.selectedProducts,
                })
                .then(() => {
                  Vue.$toast.open({
                    message: "StockUpdatd",
                    type: "success",
                  });
                  this.disabledAnnulerFBtn = false;
                  axios
                    .put(`${API_URL}/rapport/changeState/${Id}/Facturation/A`)
                    .then(() => {
                      Vue.$toast.open({
                        message: "Annulée",
                        type: "success",
                      });
                      this.FunctionToGetAllRapportMoroccoFacturation();
                      this.sockets.emit(
                        "validateRapportFacturationMaroc",
                        Id,
                        "A"
                      );
                    });
                })
                .catch((err) => {
                  this.disabledAnnulerFBtn = false;
                  if (err.response.data.error)
                    Vue.$toast.open({
                      message: err.response.data.error.error,
                      type: "error",
                    });
                });
            });
          this.$swal("cette operation a été annulée!", "", "success");
        }
      });
    },
    AnnulerMoroccoFacturationAvoir(Id) {
      this.$swal({
        title: "Voulez vous annuler?",
        confirmButtonText: `Annuler`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .put(`${API_URL}/rapport/changeState/${Id}/Facturation/A`)
            .then(() => {
              Vue.$toast.open({
                message: "Annulée",
                type: "success",
              });
              this.FunctionToGetAllRapportMoroccoFacturation();
              this.sockets.emit("validateRapportFacturationMaroc", Id, "A");
            });

          this.$swal("cette operation a été annulée!", "", "success");
        }
      });
    },
    ValideAnuulerRapportVisiteDeRecouvrement(Id, Status) {
      this.$swal({
        title: `Voulez vous ${Status == "V" ? "valider" : "annuler"}?`,
        confirmButtonText: `${Status == "V" ? "Valider" : "Annuler"}`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          axios
            .put(`${API_URL}/rapport/changeState/${Id}/Facturation/${Status}`)
            .then(() => {
              this.FunctionToGetAllRapportVisiteDeRecouvrement();
              this.sockets.emit("validateRapportRecouvremment", Id, "V");
            });
          this.$swal(
            `Cette operation a été ${Status == "V" ? "Validée" : "Annulée"}`,
            "",
            "success"
          );
        }
      });
    },
    ValideAnuulerRapportBeirut(Id, Status) {
      this.$swal({
        title: `Voulez vous ${Status == "V" ? "valider" : "annuler"}?`,
        confirmButtonText: `${Status == "V" ? "Valider" : "Annuler"}`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          axios
            .put(`${API_URL}/rapport/changeState/${Id}/FacturationB/${Status}`)
            .then(() => {
              this.FunctionToGetAllRapportBeirutFacturation();
              this.sockets.emit("validateRapportFacturationBirot", Id, "V");
            });
          this.$swal(
            `Cette operation a été ${Status == "V" ? "Validée" : "Annulée"}`,
            "",
            "success"
          );
        }
      });
    },
  },
  async mounted() {
    await this.FunctionToGetAllRapportMoroccoFacturation();
    // await this.FunctionToGetAllRapportBeirutFacturation();
    // await this.FunctionToGetAllRapportVisiteDeRecouvrement();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #b41206;
.BalckPage {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 2%;
  .leftArrow {
    button {
      width: 40px;
      height: 40px;
    }
  }
  .rigthArrow {
    button {
      width: 40px;
      height: 40px;
    }
  }
  .paginationBtn {
    background-color: $primaryColor;
    color: white;
    font-size: 20px;
    padding: 3px;
    border-radius: 50%;
    width: 40px;
    margin: 0px 4px;
    text-align: center;
  }
  .activePagination {
    background-color: $primaryColor2 !important;
  }
}
.GestionStock {
  .inputList {
    display: flex;
    div {
      padding: 5%;
    }
  }
  width: 70%;
  float: left;
  margin-left: 5%;
  padding: 2% 0% 0%;
  border-radius: 20px;
  background-color: #f2f2f2;
  ::-webkit-scrollbar {
    width: 5px;
    position: relative;
    top: 20px;
    border-radius: 30px;
  }
  /* Track */

  ::-webkit-scrollbar-track {
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
  }
  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #b41206;
    border-radius: 10px;
  }
  .GestionStock__Btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 3px solid rgb(205, 205, 205);
    padding: 0% 7%;
    h2 {
      width: 100%;
      text-align: center;
      padding: 10px 0px;
      cursor: pointer;
      margin: 0px;
      font-size: 20px;
      background-color: #d1e6e5;
      transition: all 0.2s ease-in-out;
      color: #058b84;
      &:first-of-type {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      &:hover {
        background-color: #afc5c4;
        color: white;
      }
    }

    .Active {
      background-color: #058b84;
      color: white;
    }
  }
  .BeforeFacture,
  .BL,
  .AfterFacturation,
  .BR {
    margin-top: 50px;
    height: 600px;
    overflow-y: scroll;
    margin: 0px auto;
    margin-top: 50px;
    width: 100%;
    .Informatation {
      width: 100%;
      h2 {
        text-transform: uppercase;
      }
    }
    .SingleFacturation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 30px;
      box-shadow: 5px 5px 11px -8px black;
      width: 90%;
      margin: 20px auto;
      background: linear-gradient(180deg, #f3f3f3 50%, #dfdfdf 100%);
      h2 {
        font-size: 25px;
        color: #058b84;
        font-weight: 400;
        padding-bottom: 15px;
      }
      p {
        line-height: 10px;
        font-weight: 400;
        line-height: 30px;
        color: #8a8d8d;
      }
      .Btn {
        display: flex;
        align-items: center;
        button {
          padding: 10px 30px;
          margin: 0px 30px;
          border-radius: 5px;
          font-size: 17px;
          font-weight: bold;
          color: white;
        }
        .ValiderBtn {
          border: 1px solid gray;
          background-color: white;
          color: gray;
        }
        .Valider {
          background-color: green;
          &:hover {
            border: 1px solid green;
            color: green;
            background-color: transparent;
          }
        }
        .Annuler {
          background-color: red;
          &:hover {
            border: 1px solid red;
            color: red;
            background-color: transparent;
          }
        }
      }
    }
  }
  .ValideBL {
    &:hover {
      background-color: green !important;
      color: white !important;
      border: none !important;
    }
  }
  .AnnulerBtn {
    &:hover {
      background-color: red !important;
      color: white !important;
      border: none !important;
    }
  }
}
</style>
