import Vue from "vue";
import VueRouter from "vue-router";
import ArdMap from "../views/ArdMap";
import Login from "../views/Login"
import UserList from "../views/UserList"
import EditeUser from "../views/EditeUser"
import AddUser from "../views/AddUser"
import Operations from "../views/Operations"
import OperationDetails from "../views/OperationDetails"
import OperationDetailsTwo from "../views/OperationDetailsTwo"
import Chartline from "../views/Chartline"
import Stepper from "../views/Stepper"
import Magasine from "../views/Magasin"
import ListClient from "../views/ListClient"
import ListProduct from "../views/ListProducts"
import GestionDeStock from "../views/GestionStock"

import Facturation from "../views/Facturation"
import Depot from "../views/Depot"
import GestiondesOperation from "../views/GestionOperation"
import GestiondesOperation2 from "../views/GestionOperation2"
import TransferDeStock from '../views/TransferDeStock'
import DepotParProduit from '../views/DepotParProduit'
import ProduitParDepot from '../views/ProduitParDepot'
import Recouvrement from '../views/Recouvrement'

import LVJ from '../views/LVJournalier'
import JVC from '../views/JVcomptable'
import EE from '../views/EEncaissements'

import companyFees from '../views/CompanyFees'

import addStock from '../views/addStock'

import addRole from '../views/addRole'

import GestionStockVE from '../views/GestionStockVE'

import axios from "axios";
import API_URL from "../../config";

Vue.use(VueRouter);

const routes = [
  {
    path: "/addStock",
    name: "addStock",
    component: addStock,
  },
  {
    path: "/addRole",
    name: "addRole",
    component: addRole,
  },
  {
    path: "/GestiondeStock",
    name: "GestionDeStock",
    component: GestionDeStock,
  },
  {
    path: "/Facturation",
    name: "Facturation",
    component: Facturation,
  },
  {
    path: "/Recouvrement",
    name: "Recouvrement",
    component: Recouvrement,
  },
  {
    path: "/Clients",
    name: "ListClient",
    component: ListClient,
  },
  {
    path: "/Product",
    name: "ListProduct",
    component: ListProduct,
  },
  {
    path: "/ardmap",
    name: "ard-map",
    component: ArdMap,
  },
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/userlists",
    name: "userlists",
    component: UserList,
  },
  {
    path: "/editeUser/:userId",
    name: "EditeUser",
    component: EditeUser,
  },
  {
    path: "/addUser",
    name: "AddUser",
    component: AddUser,
  },
  {
    path: "/operationsLists",
    name: "Operations",
    component: Operations,
  },
  {
    path: "/operationdetails/:OID",
    name: "OperationDetails",
    component: OperationDetails,
  },
  {
    path: "/operationdetailstwo/:OID/:SELECTED/:MagasinFor",
    name: "OperationDetailsTwo",
    component: OperationDetailsTwo,
  },
  {
    path: "/chartline",
    name: "Chartline",
    component: Chartline
  },
  // Stepper
  {
    path: "/stepper/:MagasinFor",
    name: "stepper",
    component: Stepper
  },
  {
    path: "/magasine",
    name: "magasine",
    component: Magasine
  },
  {
    path: "/Depot",
    name: "Depot",
    component: Depot
  },
  {
    path: "/GestiondesOperation",
    name: "GestiondesOperation",
    component: GestiondesOperation
  },
  {
    path: "/GestiondesOperation2",
    name: "GestiondesOperation2",
    component: GestiondesOperation2
  },
  {
    path: "/TransferDeStock",
    name: "TransferDeStock",
    component: TransferDeStock
  },
  {
    path: "/DParP",
    name: "DParP",
    component: DepotParProduit
  },
  {
    path: "/PParD",
    name: "PParD",
    component: ProduitParDepot
  },
  {
    path: "/LVJ",
    name: "LVJ",
    component: LVJ
  },
  {
    path: "/JVC",
    name: "JVC",
    component: JVC
  },
  {
    path: "/EE",
    name: "EE",
    component: EE
  },
  {
    path: "/companyFees",
    name: "companyFees",
    component: companyFees
  },
  {
    path: "/GestionStockVE",
    name: "GestionStockVE",
    component: GestionStockVE,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  var get_token = localStorage.getItem('token')
  if (to.name === 'Recouvrement' || to.name === 'userlists' || to.name === 'EditeUser' || to.name === 'AddUser' || to.name === 'Operations' || to.name === 'OperationDetails' || to.name === 'OperationDetailsTwo' || to.name === 'Chartline' || to.name === 'stepper' || to.name === 'magasine' || to.name === 'Depot' || to.name === 'GestiondesOperation' || to.name === 'TransferDeStock' || to.name === 'DParP' || to.name === 'PParD' || to.name === 'LVJ' || to.name === 'JVC' || to.name === 'EE' || to.name === 'companyFees' || to.name === 'GestionDeStock' || to.name === 'Facturation' || to.name === 'ListClient' || to.name === 'ListProduct' && get_token) {
      var get_user_id = localStorage.getItem('user_id')
      axios
      .get(`${API_URL}/superAdmin/${get_user_id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${get_token}`
        }
      })
      .then(() => {
        return next()
      })
      .catch(err => {
          console.log(err.response.data.error)
          if (to.name === 'Recouvrement' || to.name === 'userlists' || to.name === 'EditeUser' || to.name === 'AddUser' || to.name === 'Operations' || to.name === 'OperationDetails' || to.name === 'OperationDetailsTwo' || to.name === 'Chartline' || to.name === 'stepper' || to.name === 'magasine' || to.name === 'Depot' || to.name === 'GestiondesOperation' || to.name === 'TransferDeStock' || to.name === 'DParP' || to.name === 'PParD' || to.name === 'LVJ' || to.name === 'JVC' || to.name === 'EE' || to.name === 'companyFees' || to.name === 'GestionDeStock' || to.name === 'Facturation' || to.name === 'ListClient' || to.name === 'ListProduct' && err.response.data.error || err) next({ name: 'login' })
        });
  }
  else {
    if (to.name === 'Recouvrement' || to.name === 'userlists' || to.name === 'EditeUser' || to.name === 'AddUser' || to.name === 'Operations' || to.name === 'OperationDetails' || to.name === 'OperationDetailsTwo' || to.name === 'Chartline' || to.name === 'stepper' || to.name === 'magasine' || to.name === 'Depot' || to.name === 'GestiondesOperation' || to.name === 'TransferDeStock' || to.name === 'DParP' || to.name === 'PParD' || to.name === 'LVJ' || to.name === 'JVC' || to.name === 'EE' || to.name === 'companyFees' || to.name === 'GestionDeStock' || to.name === 'Facturation' || to.name === 'ListClient' || to.name === 'ListProduct')
    next({ name: 'login' })
  }
  next()
})

export default router;
