<template>
  <div>
    <div
      class="BalckPage"
      v-if="OpenEditeRecouvrement"
      @click="RemoveAllPopup"
    ></div>

    <EditeRecovrement
      v-if="OpenEditeRecouvrement"
      :selectedRecovrement="selectedRecovrement"
      @closePopup="closePopupMethod"
    />

    <IncludedNavBar />
    <LeftSideNav />
    <LogOut />

    <div class="ListClient">
      <div class="OuClientAndBtn">
        <h1>List des recouvrements</h1>
        <!-- <button>Nouveau Dépôt</button> -->
      </div>
      <div class="Filter">
      
        <div class="FilterParCatég">
          <h6>Filtrer par client :</h6>
          <input
            type="text"
            placeholder="Tout"
            v-model="clientName"
            @keyup="getAllRecouvrement"
          />
        </div>

        <div class="FilterParCatég">
          <h6>Filtrer par type :</h6>
          <select v-model="selectedTypeOfOp" @change="getAllRecouvrement">
              <option value="Tout">Tout</option>
              <option value="Cheque">Chéque</option>
              <option value="Effet">Effet</option>
              <option value="Espece">Espece</option>
              <option value="Virement">Virement</option>
          </select>
        </div>

        <div class="FilterParCatég">
          <h6>Filtrer par date :</h6>
          <v-dialog transition="dialog-bottom-transition" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="#1cb6b6"
                style="color: white"
              >
                Choisir une date
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-card-text>
                  <div class="text-h2 pa-12">
                    <v-row justify="center">
                      <v-date-picker
                        v-model="pickerDateOne"
                      range>
                      </v-date-picker>

                      <v-btn color="#1cb6b6" style="color: white" @click="getBySelectedDate">valider</v-btn>
                    </v-row>
                  </div>
                </v-card-text>
                <!-- <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">Close</v-btn>
                </v-card-actions> -->
              </v-card>
            </template>
          </v-dialog>
        </div>
      </div>
      <div class="HeaderTable">
        <h3>Date</h3>
        <h3>Type d'operation</h3>
        <h3>Bank</h3>
        <h3>Client</h3>
        <h3>N°</h3>
        <h3>Montant</h3>
        <h3>Date d'échéance</h3>
        <h3>Actions</h3>
        <h3>Impayer</h3>
        <h3>Encaisser</h3>
      </div>
      <!-- <div class="Vide">
                <h5>Vide</h5>
            </div> -->
      <div class="TableContentZone">
        <div
          class="HeaderBody"
          v-for="(recouvrementData, index) in recouvrementData"
          :key="index"
          :style="
            index % 2 == 0
              ? 'background-color: rgba(5,139,132,0.22)'
              : 'background-color: rgba(180,181,181,0.22);'
          "
        >
          <h3>{{ (recouvrementData.value.createdAt).substr(0, 10).split("-").reverse().join("-") }}</h3>
          <h3>{{ recouvrementData.key }}</h3>
          <h3>
            {{
              recouvrementData.value.Bank == ""
                ? "-"
                : recouvrementData.value.Bank
            }}
          </h3>
          <h3>{{ recouvrementData.value.client }}</h3>
          <h3>{{ recouvrementData.value.transactionNumber }}</h3>
          <h3>
            {{
              recouvrementData.value.Montant
                ? recouvrementData.value.Montant
                : recouvrementData.value.montant
            }}
          </h3>
          <h3>{{ recouvrementData.value.DateEcheance }}</h3>
          <h3>
            <svg
              v-if="!recouvrementData.value.encaissement"
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
              @click="
                viewRapport(
                  recouvrementData.value.id,
                  recouvrementData.value.TCP
                )
              "
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"
              />
            </svg>
            <svg
              v-if="!recouvrementData.value.encaissement"
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
              @click="
                (OpenEditeRecouvrement = true),
                  (selectedRecovrement = recouvrementData)
              "
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"
              />
            </svg>
            <svg
              v-if="!recouvrementData.value.encaissement"
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
              @click="
                deleteRecouvrement(
                  recouvrementData.key,
                  recouvrementData.value.id,
                  recouvrementData.value.key
                )
              "
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"
              />
            </svg>
          </h3>
          <h3>
            <v-btn :disabled="recouvrementData.value.impayer || recouvrementData.value.encaissement" color="red" style="color: white" class="mb-3 w-75" @click="Ipmayer(recouvrementData.value.codeClient, recouvrementData.value.id, recouvrementData.key, recouvrementData.value.key)">Impayer</v-btn>
          </h3>
          <h3>
            <v-btn :disabled="recouvrementData.value.encaissement" color="success" style="color: white" class="w-75" @click="encaissement(recouvrementData.value.codeClient, recouvrementData.value.id, recouvrementData.key, recouvrementData.value.key)">Encaisser</v-btn>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primaryFont: "Poppins", sans-serif;
$primaryColor: #1cb6b6;
$primaryBgColor2: rgba($primaryColor, 0.7);
$secondary-color: #12d20d;
$primaryColor2: #757575;
$primary-background-color: #00abc4;
.BalckPage {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.v-application .text-h2 {
  line-height: 0;
}

.ListClient {
  width: 80%;
  float: left;
  margin-top: 4%;
  ::-webkit-scrollbar {
    width: 10px;
    position: relative;
    top: 20px;
    border-radius: 30px;
  }
  /* Track */

  ::-webkit-scrollbar-track {
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
  }
  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #b41206;
    border-radius: 10px;
  }
  .TableContentZone {
    height: 500px;
    overflow-y: scroll;
  }
  .OuClientAndBtn {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 80%;
    margin: auto;
    button {
      font-size: 17px;
      font-family: $primaryFont;
      font-weight: 500;
      border-radius: 10px;
      padding: 6px 30px;
      background-color: #e1e1e1;
      color: #00abc4;
      &:hover {
        border: 1px solid $primary-background-color;
        background-color: transparent;
        color: $primary-background-color;
      }
    }
  }
  h1,
  h3 {
    font-family: $primaryFont;
    font-size: 40px;
    font-weight: bold;
    color: $primary-background-color;
    text-align: center;
    padding-bottom: 30px;
  }

  .HeaderTable,
  .HeaderBody {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0px auto;

    h3 {
      font-size: 17px;
      border: 1px solid white;
      padding: 10px 0px;
      background-color: #058b84;
      color: white;
      font-weight: 600;
      width: 400px;
      line-height: 20px;
      &:first-of-type {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &:last-of-type {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }
  .HeaderBody {
    margin-bottom: 10px;
    border-radius: 20px;
    h3 {
      font-size: 17px;
      padding: 10px 0px;
      color: white;
      font-weight: 600;
      width: 200px;
      overflow-wrap: break-word;
      background-color: transparent;
      border: none;
      color: #058b84;
      margin: 0px;
    }
    svg {
      fill: #058b84;
      width: 30px;
      height: 30px;
      margin: 0px 10px;
      &:hover {
        fill: $primary-background-color;
        cursor: pointer;
      }
    }
  }
  .Filter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 1% auto;
    .FilterParCatég {
      h6 {
        color: $primaryColor;
      }
    }
    h6 {
      font-family: $primaryFont;
      font-size: 18px;
      font-weight: bold;
      color: #142048;
      padding-bottom: 5px;
    }
    select,
    Input {
      border: 1px solid #035a7f;
      width: 100%;
      padding: 6px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .Vide {
    width: 80%;
    margin: 30px auto;
    background-color: #fcd309;
    padding: 10px 0px;
    text-align: center;
    h5 {
      font-size: 20px;
      font-weight: 600;
      color: #035a7f;
    }
  }
}
</style>

<script>
import IncludedNavBar from "@/components/IncludedNavBar";
import LeftSideNav from "@/components/leftSideNav";
import LogOut from "@/components/Logout";

import EditeRecovrement from "@/components/RecovrementPopup/EditeRecovrement";

import axios from "axios";
import API_URL from "../../config";

import Swal from 'sweetalert2'

export default {
  components: {
    IncludedNavBar,
    LeftSideNav,
    LogOut,

    EditeRecovrement
  },
  data() {
    return {
      recouvrementData: null,
      OpenEditeRecouvrement: false,
      selectedRecovrement: null,
      RecouvrementObject: null,

      // filter input
      clientName: "",

      pickerDateOne: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
      ],
      selectedDate: "",

      selectedTypeOfOp: "Tout"
    };
  },

  created() {
    this.getAllRecouvrement();
  },

  methods: {
    enableClient(codeClient) {
      axios.put(`${API_URL}/codeClient/disableEnableClient/${codeClient}?isActive=TRUE`)
      .then(() => {
        Swal.fire('Activée', '','success')
      })
    },
    Ipmayer(codeClient, rapportID, category, index) {
      this.$swal({
          title: "Voulez vous modifier?",
          showDenyButton: true,
          confirmButtonText: `Impayer`,
          denyButtonText: `Annuler`,
      }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios.put(`${API_URL}/codeClient/impayer/${codeClient}/${rapportID}/${category}/${index}`).then(() => {
                this.getAllRecouvrement()
                this.$swal("cette operation a été modifier!", "", "success");
            })
            .then(()=> {
              axios.post(`${API_URL}/rapport/clearRedisData?keys=["ProductByDepotDpP", "GestionOP", "LVJ", "EC"]`)
            })
          }
      })
    },
    encaissement(codeClient, rapportID, category, index) {
      // console.log(codeClient, rapportID, category, index)
      this.$swal({
          title: "Voulez vous modifier?",
          showDenyButton: true,
          confirmButtonText: `Encaisser`,
          denyButtonText: `Annuler`,
      }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            axios.put(`${API_URL}/codeClient/encaissed/${codeClient}/${rapportID}/${category}/${index}`).then(() => {
                this.getAllRecouvrement()
                this.$swal("cette operation a été modifier!", "", "success");
            })
            .then(()=> {
              axios.post(`${API_URL}/rapport/clearRedisData?keys=["ProductByDepotDpP", "GestionOP", "LVJ", "EC"]`)
            })
          }
      })
    },
    getBySelectedDate() {
      axios
        .get(
          `${API_URL}/rapport/getRapportRecouvrementSeparated?clientName=${this.clientName}&DateEcheance=["${this.pickerDateOne[0]}", "${this.pickerDateOne[1]}"]&typeOfOp=${this.selectedTypeOfOp}`
        )
        .then((res) => {
          this.recouvrementData = res.data;
        });
      this.selectedDate = "";
    },
    getAllRecouvrement() {
      axios
        .get(
          `${API_URL}/rapport/getRapportRecouvrementSeparated?clientName=${this.clientName}&DateEcheance&typeOfOp=${this.selectedTypeOfOp}`
        )
        .then((res) => {
          this.recouvrementData = res.data;
        });
      this.selectedDate = "";
    },
    viewRapport(rapportID, tcpID) {
      window.open(
        `${API_URL}/PDF/DownloadRapportFromBackRecouvrement/${rapportID}/${tcpID}/P/F`,
        "_blank"
      );
    },
    RemoveAllPopup() {
      this.OpenEditeRecouvrement = false;
    },
    closePopupMethod() {
      this.getAllRecouvrement();
      this.OpenEditeRecouvrement = false;
    },
    async RecouvrementObjectMethod(ID) {
      await axios
        .get(`${API_URL}/rapport/getOneRecovrementObject/${ID}`)
        .then((res) => {
          this.RecouvrementObject = res.data;
        });
    },
    deleteRecouvrementMethod(OP, ID, index) {
      if (OP == "Cheque" || OP == "Effet") {
        for (let key in this.RecouvrementObject.recouvrement[OP]) {
          if (
            Array.isArray(this.RecouvrementObject.recouvrement[OP][key]) &&
            this.RecouvrementObject.recouvrement[OP][key].length != 0
          ) {
            this.RecouvrementObject.recouvrement[OP][key].splice(index, 1);

            this.RecouvrementObject.recouvrement[OP][key].map((el, index) => {
              el.key = index;
            });
          }
        }
      } else
        this.RecouvrementObject.recouvrement[OP] = {
          montant: "",
          Nfacture: "",
        };

      this.$swal({
        title: "Voulez vous suprimer?",
        showDenyButton: true,
        confirmButtonText: `Suprimer`,
        denyButtonText: `Annuler`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .put(
              `${API_URL}/rapport/updateRecouvrementDate/${ID}`,
              this.RecouvrementObject
            )
            .then(() => {
              this.getAllRecouvrement();
              this.$swal("cette operation a été suprimée !", "", "success");
            });
        } else if (result.isDenied) {
          this.$swal("cette operation a été annulée !", "", "info");
        }
      });
    },
    async deleteRecouvrement(OP, ID, index) {
      await this.RecouvrementObjectMethod(ID);
      await this.deleteRecouvrementMethod(OP, ID, index);
    },
  },
};
</script>
